/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 assets/3d/Boxes/Boxes.gltf
Created: Wed May 31 11:20:39 2023
*/

import { useGLTF, useTexture } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import React from 'react';

import { ExtendedGLTF } from '../../../types';
import ModelWrapper from './ModelWrapper';

export default function Model() {
  const { nodes, materials } = useGLTF('/3d/Boxes/Boxes.gltf') as ExtendedGLTF;
  const lightMapTexture = useTexture('/3d/Boxes/Boxes_Lightmap.jpg');
  const { scene } = useThree();

  materials.Boxes_mat.lightMap = lightMapTexture;
  materials.Boxes_mat.lightMapIntensity = 5;
  materials.Boxes_mat.lightMap.flipY = false;
  materials.Boxes_mat.envMap = scene.background;
  materials.Boxes_mat.envMapIntensity = 3;

  return (
    <ModelWrapper>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Boxes.geometry}
        material={materials.Boxes_mat}
      />
    </ModelWrapper>
  );
}

useGLTF.preload(['/3d/Boxes/Boxes.gltf', '/3d/Boxes/Boxes_Lightmap.jpg']);

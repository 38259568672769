import type { ReadConfig } from 'api-read-hook';
import { useApiRead } from 'api-read-hook';

import * as Upload from './Upload';

export type V_MyOverview = {
  '@id': string;
  '@type': 'User';
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  dob: string;
  avatar: Upload.BaseView | null;
  roomSlug: string;
  roomPin: string | null;
  roomTriggers: Array<{
    '@id': string;
    '@type': 'RoomTrigger';
    id: string;
    type: string;
    slot: number | null;
  }>;
};

export type V_Visitor = {
  '@id': string;
  '@type': 'User';
  id: string;
  firstName: string;
  lastName: string;
  avatar: Upload.BaseView | null;
  roomSlug: string;
  roomTriggers: Array<{
    '@id': string;
    '@type': 'RoomTrigger';
    id: string;
    type: string;
    slot: number | null;
    album: {
      '@id': string;
      '@type': 'Album';
      id: string;
      title: string;
      story: string | null;
      safeCover: {
        '@id': string;
        '@type': 'AlbumMedia';
        upload: Upload.BaseView;
      } | null;
    } | null;
  }>;
  hasRoomPin: boolean;
  roomPinLength: number | null;
};

export type V_VisitorEntry = {
  '@id': string;
  '@type': 'User';
  id: string;
  firstName: string;
  lastName: string;
  avatar: Upload.BaseView | null;
  roomSlug: string;
  hasRoomPin: boolean;
  roomPinLength: number | null;
};

export type W_Create = {
  email: string;
  plainPassword: string | null;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  dob: string;
  avatar: string | null;
};

export type W_Update = {
  avatar: string | null;
  roomSlug: string;
  roomPin: string | null;
};

export function useReadItem(options?: ReadConfig) {
  return useApiRead<V_MyOverview>(`/my-overview`, options);
}

export function useReadItemVisitor(roomSlug: string, options?: ReadConfig) {
  return useApiRead<V_Visitor>(`/users/${roomSlug}/visitor`, options);
}

export function useReadItemVisitorEntry(
  roomSlug: string,
  options?: ReadConfig,
) {
  return useApiRead<V_VisitorEntry>(
    `/users/${roomSlug}/visitor-entry`,
    options,
  );
}

import * as React from 'react';
import * as THREE from 'three';

import { WalkIndicatorCircle } from '../3d';
import { useMovementContext } from '../contexts';

// https://github.com/pmndrs/react-three-fiber/issues/19
const walkAreaPoints = [
  // Start in left bookshelf corner
  [-2.9, 2.2],
  // Reaching right bookshelf corner
  [-2.9, -2.0],
  [-2.5, -2.0],
  [-2.5, -1.7],
  [-0.65, -1.7],
  [-0.65, -1.2],
  [2.5, -1.2],
  [2.5, -1.5],
  // Reaching Back right corner
  [3.9, -1.5],
  // Reaching back left corner
  [3.9, 1.4],
  [2.95, 1.4],
  [2.3, 0.85],
  [1.55, 1.5],
  [-2.2, 1.8],
  [-2.3, 2.2],
];
const shape = new THREE.Shape();
shape.moveTo(walkAreaPoints[0][0], walkAreaPoints[0][1]);
for (let i = 1; i < walkAreaPoints.length; i++) {
  shape.lineTo(walkAreaPoints[i][0], walkAreaPoints[i][1]);
}

export default function WalkIndicator() {
  const { setMovementTarget } = useMovementContext();

  const [walkIndicatorPoint, setWalkIndicatorPoint] =
    React.useState<THREE.Vector3 | null>(null);

  const [lastPointerDownPagePos, setLastPointerDownPagePos] = React.useState<
    null | [number, number]
  >(null);

  return (
    <>
      <mesh
        rotation={[Math.PI / 2, 0, 0]}
        position={[0, 0.07, 0]}
        onPointerOut={() => setWalkIndicatorPoint(null)}
        onPointerMove={(ev) => {
          setWalkIndicatorPoint(ev.point);
        }}
        onPointerDown={(ev) => {
          setLastPointerDownPagePos([ev.pageX, ev.pageY]);
        }}
        onPointerUp={(ev) => {
          if (!lastPointerDownPagePos) return;
          const [lastX, lastY] = lastPointerDownPagePos;
          if (
            Math.abs(lastX - ev.pageX) < 10 &&
            Math.abs(lastY - ev.pageY) < 10
          ) {
            setMovementTarget(new THREE.Vector3(ev.point.x, 1.75, ev.point.z));
          }
          setLastPointerDownPagePos(null);
        }}
      >
        <shapeGeometry args={[shape]} />
        <meshBasicMaterial
          color="orange"
          side={THREE.DoubleSide}
          transparent
          // Set to 1 to see walkable area
          opacity={0}
        />
      </mesh>
      {walkIndicatorPoint && (
        <group position={[walkIndicatorPoint.x, 0.08, walkIndicatorPoint.z]}>
          <WalkIndicatorCircle />
        </group>
      )}
    </>
  );
}

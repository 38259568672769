import { useCursor } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import * as React from 'react';
import * as THREE from 'three';

import { useCameraContext } from './CameraContext';

const HoverContext = React.createContext<{
  hoverTarget: string | null;
  setHoverTarget: (target: string | null) => void;
}>({
  hoverTarget: null,
  setHoverTarget: () => {},
});

type Props = {
  children: React.ReactNode;
};

const raycaster = new THREE.Raycaster();

/**
 * Show outline over what's hovered with the mouse (desktop) or in center of
 * the screen (mobile)
 */
export function HoverContextProvider({ children }: Props) {
  const { cameraRef } = useCameraContext();

  const [hoverTarget, setHoverTarget] = React.useState<string | null>(null);
  useCursor(!!hoverTarget, 'pointer', 'grab');

  const { scene } = useThree();

  useFrame(() => {
    // Don't bother running if it's not a touch screen (they can just use mouse)
    if (!('ontouchstart' in window)) {
      return;
    }

    const normalizedPosition = new THREE.Vector2(0, 0);
    raycaster.setFromCamera(
      normalizedPosition, // normalized (-1 to +1)
      cameraRef.current,
    );

    const intersects = raycaster.intersectObjects(scene.children);
    for (const intersect of intersects) {
      const intersectingTarget = intersect.object?.userData?.hoverTarget;
      if (intersectingTarget) {
        if (intersectingTarget !== hoverTarget) {
          setHoverTarget(intersectingTarget);
        }
        return;
      }
    }
    if (hoverTarget) {
      setHoverTarget(null);
    }
  });

  return (
    <HoverContext.Provider
      value={{
        hoverTarget,
        setHoverTarget,
      }}
    >
      {children}
    </HoverContext.Provider>
  );
}

export function useHoverContext() {
  return React.useContext(HoverContext);
}

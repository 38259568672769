import { OrbitControls, PerspectiveCamera } from '@react-three/drei';

import { INITIAL_CAM_POS, INITIAL_TARGET } from '../constants';
import { useCameraContext } from '../contexts';

export default function OrbitCamera() {
  const { cameraRef, orbitRef } = useCameraContext();

  return (
    <>
      <PerspectiveCamera
        ref={cameraRef}
        makeDefault
        fov={55}
        position={INITIAL_CAM_POS}
      />
      <OrbitControls
        ref={orbitRef}
        enablePan={false}
        enableZoom={false}
        target={INITIAL_TARGET}
        reverseOrbit
        enableDamping
        dampingFactor={0.125}
        rotateSpeed={0.25}
        minZoom={0.1}
        maxZoom={0.1}
      />
    </>
  );
}

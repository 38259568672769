/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 assets/3d/Sofa/Sofa.gltf
Created: Wed May 31 08:25:40 2023
*/

import { useGLTF, useTexture } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import React from 'react';

import { ExtendedGLTF } from '../../../types';
import ModelWrapper from './ModelWrapper';

export default function Model() {
  const { nodes, materials } = useGLTF('/3d/Sofa/Sofa.gltf') as ExtendedGLTF;
  const lightMap = useTexture('/3d/Sofa/Sofa_Lightmap.jpg');
  const { scene } = useThree();

  materials.Sofa_wood_mat.lightMap = lightMap;
  materials.Sofa_wood_mat.lightMapIntensity = 4;
  materials.Sofa_wood_mat.lightMap.flipY = false;
  materials.Sofa_wood_mat.envMap = scene.background;
  materials.Sofa_wood_mat.envMapIntensity = 4;

  materials.Sofa_fabric_mat.lightMap = lightMap;
  materials.Sofa_fabric_mat.lightMapIntensity = 6;
  materials.Sofa_fabric_mat.lightMap.flipY = false;
  materials.Sofa_fabric_mat.envMap = scene.background;
  materials.Sofa_fabric_mat.envMapIntenisty = 3;

  return (
    <ModelWrapper>
      <mesh
        geometry={nodes.Sofa_wood.geometry}
        material={materials.Sofa_wood_mat}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.Sofa_fabric.geometry}
        material={materials.Sofa_fabric_mat}
        castShadow
        receiveShadow
      />
    </ModelWrapper>
  );
}

useGLTF.preload(['/3d/Sofa/Sofa.gltf', '/3d/Sofa/Sofa_Lightmap.jpg']);

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 assets/3d/Cushions/Cushions.gltf
Created: Wed May 31 15:25:09 2023
*/

import { useGLTF, useTexture } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import React from 'react';

import { ExtendedGLTF } from '../../../types';
import ModelWrapper from './ModelWrapper';

export default function Model() {
  const { nodes, materials } = useGLTF(
    '/3d/Cushions/Cushions.gltf',
  ) as ExtendedGLTF;
  const lightMap = useTexture('/3d/Cushions/Cushions_Lightmap.jpg');
  const { scene } = useThree();

  materials.Cushions_mat.lightMap = lightMap;
  materials.Cushions_mat.lightMapIntensity = 6;
  materials.Cushions_mat.lightMap.flipY = false;
  materials.Cushions_mat.envMap = scene.background;
  materials.Cushions_mat.envMapIntensity = 4;
  materials.Cushions_mat.aoMap = lightMap;

  return (
    <ModelWrapper>
      <mesh
        geometry={nodes.Cushions.geometry}
        material={materials.Cushions_mat}
        receiveShadow
        castShadow
      />
    </ModelWrapper>
  );
}

useGLTF.preload([
  '/3d/Cushions/Cushions.gltf',
  '/3d/Cushions/Cushions_Lightmap.jpg',
]);

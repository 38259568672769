const spacing = 20;

export default {
  contentMinWidth: 1025,

  cornerRadiusExtraLarge: 20,
  cornerRadiusLarge: 10,
  cornerRadiusSmall: 5,

  headerHeight: 70,
  spacing,
};

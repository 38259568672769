/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 assets/3d/SeaSky/sea_sky.gltf
Created: Mon Oct  9 10:21:09 2023
*/

import { useGLTF, useTexture } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import React from 'react';

import { ExtendedGLTF } from '../../../types';
import { ModelWrapper } from '.';

export default function Model() {
  const { nodes, materials } = useGLTF(
    '/3d/SeaSky/sea_sky.gltf',
  ) as ExtendedGLTF;
  const lightMap = useTexture('/3d/SeaSky/sky_lightmap.jpg');
  const { scene } = useThree();

  materials.sky_mat.lightMap = lightMap;
  materials.sky_mat.envMap = scene.background;
  materials.sky_mat.lightMap.flipY = false;
  materials.sky_mat.lightMapIntensity = 1.2;
  materials.sky_mat.envMapIntensity = 0;

  materials.sea_mat.envMap = scene.background;

  return (
    <ModelWrapper>
      <mesh geometry={nodes.sea.geometry} material={materials.sea_mat} />
      <mesh geometry={nodes.sky.geometry} material={materials.sky_mat} />
    </ModelWrapper>
  );
}

useGLTF.preload('/3d/SeaSky/sea_sky.gltf', '/3d/SeaSky/sky_lightmap.jpg');

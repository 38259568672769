/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 assets/3d/Books/Books.gltf
Created: Fri Jun  2 16:58:39 2023
*/

import { useGLTF, useTexture } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { Select } from '@react-three/postprocessing';
import React from 'react';
import * as THREE from 'three';

import { Api } from '../../../api';
import { useScreenRouterContext } from '../../../contexts';
import { ExtendedGLTF } from '../../../types';
import { useHoverContext, useMovementContext } from '../contexts';
import { Text } from './dynamic';

type Props = {
  books?: Api.User.V_Visitor['roomTriggers'];
};

export default function Model({ books }: Props) {
  const { nodes, materials } = useGLTF('/3d/Books/Books.gltf') as ExtendedGLTF;
  const { hoverTarget, setHoverTarget } = useHoverContext();
  const lightMapTexture = useTexture('/3d/Books/Books_Lightmap.jpg');
  const { scene } = useThree();
  const { setMovementTarget } = useMovementContext();
  const { showScreen } = useScreenRouterContext();

  materials.Books_mat.lightMap = lightMapTexture;
  materials.Books_mat.lightMapIntensity = 2;
  materials.Books_mat.lightMap.flipY = false;
  materials.Books_mat.envMap = scene.background;
  materials.Books_mat.envMapIntensity = 3;

  const book1 = books && books.length > 0 ? books[0] : null;
  const book1HoverId = book1 ? `Book.${book1.id}` : undefined;
  const book2 = books && books.length > 1 ? books[1] : null;
  const book2HoverId = book2 ? `Book.${book2.id}` : undefined;
  const book3 = books && books.length > 2 ? books[2] : null;
  const book3HoverId = book3 ? `Book.${book3.id}` : undefined;

  return (
    <group dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.book_001.geometry}
        material={materials.Books_mat}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.book_002.geometry}
        material={materials.Books_mat}
      />
      <Select enabled={hoverTarget === book1HoverId}>
        <mesh
          userData={{ hoverTarget: book1HoverId }}
          onPointerOver={() => {
            book1HoverId && setHoverTarget(book1HoverId);
          }}
          onPointerOut={() => setHoverTarget(null)}
          onClick={() => {
            if (book1 && book1.album) {
              setMovementTarget(new THREE.Vector3(-2.261, 2.215, -1.76));
              setTimeout(() => {
                showScreen(['Album', { albumId: book1.album!.id }]);
              }, 500);
            }
          }}
          castShadow
          receiveShadow
          geometry={nodes.book_003.geometry}
          material={materials.Books_mat}
        />
      </Select>
      {book1 && book1.album && (
        <Text
          text={book1.album.title}
          position={new THREE.Vector3(-0.255, 2.205, -1.76)}
          maxLineLength={25}
          multiline
          fontSize={0.035}
        />
      )}
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.book_004.geometry}
        material={materials.Books_mat}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.book_005.geometry}
        material={materials.Books_mat}
      ></mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.book_006.geometry}
        material={materials.Books_mat}
      />
      <Select enabled={hoverTarget === book2HoverId}>
        <mesh
          userData={{ hoverTarget: book2HoverId }}
          onPointerOver={() => {
            book2HoverId && setHoverTarget(book2HoverId);
          }}
          onPointerOut={() => setHoverTarget(null)}
          onClick={() => {
            if (book2 && book2.album) {
              setMovementTarget(new THREE.Vector3(-2.26, 1.07, -1.79));
              setTimeout(() => {
                showScreen(['Album', { albumId: book2.album!.id }]);
              }, 500);
            }
          }}
          castShadow
          receiveShadow
          geometry={nodes.book_007.geometry}
          material={materials.Books_mat}
        />
      </Select>
      {book2 && book2.album && (
        <Text
          color={'#fff7eb'}
          text={book2.album.title}
          position={new THREE.Vector3(-0.26, 1.07, -1.79)}
          multiline
          maxLineLength={30}
          fontSize={0.04}
        />
      )}
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.book_008.geometry}
        material={materials.Books_mat}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.book_009.geometry}
        material={materials.Books_mat}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.book_010.geometry}
        material={materials.Books_mat}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.book_011.geometry}
        material={materials.Books_mat}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.book_012.geometry}
        material={materials.Books_mat}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.book_013.geometry}
        material={materials.Books_mat}
      />
      <Select enabled={hoverTarget === book3HoverId}>
        <mesh
          userData={{ hoverTarget: book3HoverId }}
          onPointerOver={() => {
            book3HoverId && setHoverTarget(book3HoverId);
          }}
          onPointerOut={() => setHoverTarget(null)}
          onClick={() => {
            if (book3 && book3.album) {
              setMovementTarget(new THREE.Vector3(-2.261, 1.17, 2.111));
              setTimeout(() => {
                showScreen(['Album', { albumId: book3.album!.id }]);
              }, 500);
            }
          }}
          castShadow
          receiveShadow
          geometry={nodes.book_014.geometry}
          material={materials.Books_mat}
        />
      </Select>
      {book3 && book3.album && (
        <Text
          text={book3.album.title}
          position={new THREE.Vector3(-0.304, 1.17, 2.11)}
          rotation={[0, Math.PI / 2, -Math.PI / 2]}
          maxLineLength={22}
          fontSize={0.025}
          multiline
        />
      )}
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.book_015.geometry}
        material={materials.Books_mat}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.book_016.geometry}
        material={materials.Books_mat}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.book_017.geometry}
        material={materials.Books_mat}
      />
    </group>
  );
}

useGLTF.preload('/3d/Books/Books.gltf', '/3d/Books/Books_Lightmap.jpg');

import * as React from 'react';
import { StyleSheet, View, useWindowDimensions } from 'react-native';

import { Api } from '../../api';
import { IconButton, LRText, Spacer } from '../../components';
import {
  IconChevronLeft,
  IconChevronRight,
  IconCrossBig,
} from '../../components/icons';
import { Dimen, Style } from '../../constants';
import { useScreenRouterContext } from '../../contexts';
import { useMovementContext } from '../room/contexts';
import MediaView from './MediaView';

type Props = {
  album: Api.Album.V_Detail;
};

export default function ViewAlbumLoaded({ album }: Props) {
  const { albumMedias } = album;

  const { popBack } = useMovementContext();
  const { dismissScreen } = useScreenRouterContext();

  // Index of -1 if the Album has a Story
  const firstIndex = album.story ? -1 : 0;
  const [currentIndex, setCurrentIndex] = React.useState<number>(firstIndex);
  const hasPrev = currentIndex > firstIndex;
  const hasNext = currentIndex < albumMedias.length - 1;
  const currentMedia = albumMedias[currentIndex];

  const { width: windowWidth } = useWindowDimensions();
  const isCompactLayout = windowWidth < 700;

  function onDismiss() {
    dismissScreen();
    setTimeout(() => {
      popBack();
    }, 100);
  }

  return (
    <>
      <View style={[StyleSheet.absoluteFill, styles.container]}>
        <View style={[Style.fill, Style.row, Style.centerBoth]}>
          <View
            pointerEvents="box-none"
            style={[
              styles.arrowHolder,
              isCompactLayout && styles.arrowHolderCompact,
              isCompactLayout && { left: 0 },
            ]}
          >
            {hasPrev && (
              <IconButton
                icon={IconChevronLeft}
                size={isCompactLayout ? 52 : 70}
                iconSize={isCompactLayout ? 18 : 32}
                onPress={() => setCurrentIndex(currentIndex - 1)}
              />
            )}
          </View>
          <View style={styles.areaMain}>
            <Spacer size={isCompactLayout ? 1 : 3} />
            <View
              style={[
                Style.row,
                styles.header,
                isCompactLayout && styles.headerMobile,
              ]}
            >
              <LRText
                color="whiteMedium"
                typeface={isCompactLayout ? 'body2' : 'body1'}
                style={{ width: 50 }}
                numberOfLines={1}
              >
                {currentIndex === -1
                  ? ' '
                  : `${currentIndex + 1} / ${albumMedias.length}`}
              </LRText>
              {album.title && (
                <LRText
                  color="whiteHigh"
                  typeface="h1"
                  textAlign="center"
                  numberOfLines={1}
                  style={Style.fill}
                >
                  {album.title}
                </LRText>
              )}
              <View style={{ width: 50, alignItems: 'flex-end' }}>
                {isCompactLayout && (
                  <IconButton
                    size={38}
                    onPress={onDismiss}
                    icon={IconCrossBig}
                    iconSize={24}
                  />
                )}
              </View>
            </View>
            <Spacer />
            {currentMedia ? (
              <>
                <View style={[Style.fill, Style.justifyCenter]}>
                  {currentMedia && <MediaView media={currentMedia} />}
                </View>
                {currentMedia.description && (
                  <View style={styles.description}>
                    <LRText
                      color="whiteHigh"
                      typeface={isCompactLayout ? 'body2' : 'body1'}
                    >
                      {currentMedia.description}
                    </LRText>
                  </View>
                )}
              </>
            ) : (
              <View style={styles.story}>
                <LRText
                  color="whiteHigh"
                  typeface={isCompactLayout ? 'body2' : 'body1'}
                >
                  {album.story}
                </LRText>
              </View>
            )}
          </View>
          <View
            pointerEvents="box-none"
            style={[
              styles.arrowHolder,
              isCompactLayout && styles.arrowHolderCompact,
              isCompactLayout && { right: 0 },
            ]}
          >
            {hasNext && (
              <IconButton
                icon={IconChevronRight}
                size={isCompactLayout ? 52 : 70}
                iconSize={isCompactLayout ? 18 : 32}
                onPress={() => setCurrentIndex(currentIndex + 1)}
              />
            )}
          </View>
        </View>
        {!isCompactLayout && (
          <View style={styles.closeButton}>
            <IconButton
              size={70}
              onPress={onDismiss}
              icon={IconCrossBig}
              iconSize={42}
            />
          </View>
        )}
      </View>
      {/* Preload other photos */}
      {/* <div style={{ position: 'fixed', left: 5000, top: 5000 }}>
        {albumMedias.map((slide) =>
          slide.photoUrl ? (
            <Image
              source={{ uri: slide.photoUrl }}
              style={styles.preloadImage}
              resizeMode="contain"
            />
          ) : null
        )}
          </div> */}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#000a',
    // @ts-ignore
    cursor: 'default',
  },
  arrowHolder: {
    marginTop: 112, // offset by desktop header height
    width: 120,
    alignItems: 'center',
  },
  arrowHolderCompact: {
    marginTop: 78, // offset by mobile header height
    position: 'absolute',
    top: 0,
    bottom: 0,
    justifyContent: 'center',
    zIndex: 50,
    width: 60,
  },
  areaMain: {
    alignSelf: 'stretch',
    maxWidth: 984,
    flex: 1,
  },
  header: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  headerMobile: {
    paddingHorizontal: Dimen.spacing * 0.5,
  },
  description: {
    overflowY: 'auto',
    height: 140,
    margin: Dimen.spacing * 0.5,
  },
  story: {
    flex: 1,
    // justifyContent: 'center', // causes top to get clipped off
    overflowY: 'auto',
    margin: Dimen.spacing * 0.5,
    paddingTop: Dimen.spacing,
  },
  closeButton: {
    position: 'absolute',
    top: Dimen.spacing * 0.5,
    right: Dimen.spacing,
  },
  preloadImage: {
    width: 10,
    height: 10,
  },
});

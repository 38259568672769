import { useProgress } from '@react-three/drei';
import * as React from 'react';
import { Image, StyleSheet, View } from 'react-native';

import { Button, LRText, PaddedArea, Spacer } from '../../components';
import { Color, Dimen } from '../../constants';
import { Style } from '../../constants';
import {
  useLayoutContext,
  useScreenRouterContext,
  useVisitorDataContext,
} from '../../contexts';
import Keypad from './Keypad';

const HeaderImage = require('../../../assets/img/pods/auth/illustrations/header-image.png');

const getScreenPadding = (isMobileLayout: boolean, includesKeypad: boolean) => {
  if (isMobileLayout) {
    return 1;
  }
  if (includesKeypad) {
    return 3;
  }
  return 10;
};

export default function SplashScreen() {
  const { entryData } = useVisitorDataContext();
  const { progress } = useProgress();
  const { isMobileLayout } = useLayoutContext();
  const { dismissScreen } = useScreenRouterContext();

  const accessCodeTemplateColumns = React.useMemo(() => {
    return isMobileLayout ? '1fr' : '2fr 1fr';
  }, [isMobileLayout]);

  return (
    <PaddedArea
      v={1}
      h={getScreenPadding(isMobileLayout, entryData.hasRoomPin)}
      style={styles.container}
    >
      <Image style={styles.headerImage} source={HeaderImage} />
      <Spacer />
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: entryData.hasRoomPin
            ? accessCodeTemplateColumns
            : '1fr',
          gridGap: Dimen.spacing,
        }}
      >
        <PaddedArea
          v={isMobileLayout ? 1.5 : 3}
          h={isMobileLayout ? 1.5 : 3}
          style={[
            styles.borderedContainer,
            isMobileLayout && ({ order: 1 } as any),
          ]}
        >
          {entryData.avatar && (
            <View
              style={[styles.imageContainer, isMobileLayout && styles.centered]}
            >
              <Image
                style={styles.avatarImage}
                source={entryData.avatar.pathAvatar as any}
              />
            </View>
          )}
          <LRText
            style={isMobileLayout && styles.centered}
            color="accent400"
            typeface={isMobileLayout ? 'h1' : 'titleL'}
          >
            {entryData.firstName} {entryData.lastName}
          </LRText>
          <LRText
            style={isMobileLayout && styles.centered}
            color="accent400"
            typeface={isMobileLayout ? 'h1' : 'titleL'}
          >
            Legacy Room
          </LRText>
          <Spacer size={isMobileLayout ? 1 : 2} />
          <LRText
            color="accent400"
            typeface={isMobileLayout ? 'body1' : 'titleS'}
          >
            Once in the room, each of the populated areas will be highlighted as
            you move around, or scroll over them. Click a highlighted area to
            see the contents in them.
          </LRText>
          <Spacer size={1.5} />

          {!entryData.hasRoomPin && (
            <View style={isMobileLayout && Style.fullWidth}>
              <Button
                buttonType={progress < 100 ? 'hollowAccent' : 'primary'}
                buttonPadding="large"
                title={
                  progress < 100
                    ? `Loading ${Math.floor(progress)} / 100`
                    : 'Enter Room'
                }
                disabled={progress < 100}
                onPress={() => {
                  dismissScreen();
                }}
              />
            </View>
          )}
        </PaddedArea>
        {entryData.hasRoomPin && <Keypad />}
      </div>
      <Spacer size={2} />
    </PaddedArea>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: Color.whiteHigh,
    overflow: 'scroll',
    justifyContent: 'center',
    ...StyleSheet.absoluteFillObject,
  },
  headerImage: {
    width: 144,
    height: 110,
  },
  borderedContainer: {
    flex: 2,
    borderColor: Color.accent400,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderRadius: 22,
    alignItems: 'flex-start',
    backgroundColor: Color.lightGrey,
    justifyContent: 'center',
  },
  imageContainer: {
    height: 133,
    width: 133,
    borderRadius: 77,
    backgroundColor: Color.whiteHigh,
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${Color.accent}`,
  },
  avatarImage: {
    height: 113,
    width: 113,
    borderRadius: 107,
    overflow: 'hidden',
  },
  centered: {
    alignSelf: 'center',
  },
});

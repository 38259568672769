import type { ReadConfig } from 'api-read-hook';
import { useApiRead } from 'api-read-hook';

import type { HydraCollection } from '../../types';
import * as Upload from './Upload';

export type V_UserSub = {
  '@id': string;
  '@type': 'Friendship';
  id: string;
  user: {
    '@id': string;
    '@type': 'User';
    firstName: string;
    lastName: string;
    avatar: Upload.BaseView | null;
  };
  friend: {
    '@id': string;
    '@type': 'User';
    firstName: string;
    lastName: string;
    avatar: Upload.BaseView | null;
  };
  album: {
    '@id': string;
    '@type': 'Album';
    id: string;
    title: string;
    mediasCount: number;
    safeCover: {
      '@id': string;
      '@type': 'AlbumMedia';
      upload: Upload.BaseView;
    } | null;
  } | null;
};

export type W = {
  friend: string;
};

export function useReadCollectionFriendships(id: string, options?: ReadConfig) {
  return useApiRead<HydraCollection<V_UserSub>>(
    `/users/${id}/friendships`,
    options,
  );
}

import { useTexture } from '@react-three/drei';
import * as React from 'react';
import { Texture } from 'three';

type Props = {
  sourceUrl: string;
  /**
   * w, h
   */
  sourceDimensions: Array<string | number>;
  /**
   * w / h
   */
  targetAspectRatio: number;
};

/**
 * Calcualtes and applies texture.repeat/offset to make a source texture cover
 * a target size.
 */
export default function useCroppedTexture({
  sourceUrl,
  sourceDimensions,
  targetAspectRatio,
}: Props): Texture {
  const texture = useTexture(sourceUrl) as Texture;

  React.useEffect(() => {
    let w, h;
    if (sourceDimensions && sourceDimensions.length >= 2) {
      w =
        typeof sourceDimensions[0] === 'string'
          ? parseInt(sourceDimensions[0], 10)
          : sourceDimensions[0];
      h =
        typeof sourceDimensions[1] === 'string'
          ? parseInt(sourceDimensions[1], 10)
          : sourceDimensions[1];
    }
    if (w && !isNaN(w) && h && !isNaN(h)) {
      const sourceAspectRatio = w / h;
      if (sourceAspectRatio > targetAspectRatio) {
        texture.repeat.set(1 / (sourceAspectRatio / targetAspectRatio), 1);
        texture.offset.set((1 - texture.repeat.x) / 2, 0);
      } else {
        texture.repeat.set(1, 1 / (targetAspectRatio / sourceAspectRatio));
        texture.offset.set(0, (1 - texture.repeat.y) / 2);
      }
    }
  }, [sourceDimensions, targetAspectRatio, texture]);

  return texture;
}

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 assets/3d/Frames/Frames.gltf
Created: Thu Jul  6 02:33:10 2023
*/

import { useGLTF } from '@react-three/drei';
import { Select } from '@react-three/postprocessing';
import React from 'react';
import * as THREE from 'three';

import { Api } from '../../../../api';
import { useScreenRouterContext } from '../../../../contexts';
import { ExtendedGLTF } from '../../../../types';
import { useHoverContext, useMovementContext } from '../../contexts';
import { useCroppedTexture } from '../../hooks';

type Props = React.ComponentProps<'group'> & {
  roomTrigger: Api.User.V_Visitor['roomTriggers'][0];
};

export default function Frame({ roomTrigger, ...rest }: Props) {
  const { album } = roomTrigger;
  if (!album) {
    throw new Error(
      'Frame should only be rendered on RoomTriggers with an album',
    );
  }

  const upload = album.safeCover?.upload;
  if (!upload) return null;

  const { nodes, materials } = useGLTF(
    '/3d/Frames/Frames.gltf',
  ) as ExtendedGLTF;
  const { hoverTarget, setHoverTarget } = useHoverContext();
  const { setMovementTarget } = useMovementContext();
  const { showScreen } = useScreenRouterContext();

  const FRAME_POSITIONS = [
    new THREE.Vector3(1.25, 1.02, -2.05),
    new THREE.Vector3(1.475, 1.02, -2.025),
    new THREE.Vector3(1.8, 1.02, -2.05),
    new THREE.Vector3(-0.34, 1.5, -1.9),
    new THREE.Vector3(-0.34, 1.5, 1.8),
  ];

  const FRAME_ROTATIONS = [
    THREE.MathUtils.degToRad(22.5),
    THREE.MathUtils.degToRad(0),
    THREE.MathUtils.degToRad(337.5),
    THREE.MathUtils.degToRad(70),
    THREE.MathUtils.degToRad(70),
  ];

  const isLandscape =
    parseInt(upload.file.dimensions[0], 10) >=
    parseInt(upload.file.dimensions[1], 10);

  const texture = useCroppedTexture({
    sourceUrl: upload.pathOrganiserCover,
    sourceDimensions: upload.file.dimensions,
    targetAspectRatio: isLandscape ? 4 / 3 : 3 / 4,
  });

  // Clone the material so each model instance can show something different
  const clonedMaterial = isLandscape
    ? materials.Frames_Landscape_Image_001_mat.clone()
    : materials.Frames_Portrait_Image_001_mat.clone();
  clonedMaterial.map = texture;
  clonedMaterial.map.flipY = false;
  clonedMaterial.needsUpdate = true;

  const hoverId = `Frame.${roomTrigger.id}`;

  return (
    <group
      {...rest}
      rotation={[0, FRAME_ROTATIONS[(roomTrigger.slot ?? 1) - 1], 0]}
      position={FRAME_POSITIONS[(roomTrigger.slot ?? 1) - 1]}
      dispose={null}
    >
      <mesh
        userData={{ hoverTarget: hoverId }}
        onPointerOver={() => setHoverTarget(hoverId)}
        onPointerOut={() => setHoverTarget(null)}
        position={[0, 0.14, -0.02]}
        rotation={[THREE.MathUtils.degToRad(-15), 0, 0]}
        onClick={() => {
          setMovementTarget(
            FRAME_POSITIONS[(roomTrigger.slot ?? 1) - 1].add(
              new THREE.Vector3(-2.5, 0.5, 0.5),
            ),
          );
          setTimeout(() => {
            showScreen(['Album', { albumId: album.id }]);
          }, 500);
        }}
      >
        <boxGeometry
          args={isLandscape ? [0.3, 0.5, 0.04] : [0.25, 0.6, 0.04]}
        />
        <meshStandardMaterial
          color="orange"
          transparent
          // Set to 1 to see hover area
          opacity={0}
        />
      </mesh>
      {isLandscape ? (
        <Select enabled={hoverTarget === hoverId}>
          <mesh
            geometry={nodes.Frames_Landscape_Image_001.geometry}
            material={clonedMaterial}
          />
          <mesh
            geometry={nodes.Frames_Landscape_Support_001.geometry}
            material={materials._Black}
          />
          <mesh
            geometry={nodes.Frames_Landscape_White_001.geometry}
            material={materials.Frames_White_mat}
          />
          <mesh
            geometry={nodes.Frames_Landscape_Frame_001.geometry}
            material={materials.Frames_Frame_mat}
          />
        </Select>
      ) : (
        <Select enabled={hoverTarget === hoverId}>
          <mesh
            geometry={nodes.Frames_Portrait_White_001.geometry}
            material={materials.Frames_White_mat}
          />
          <mesh
            geometry={nodes.Frames_Portrait_Frame_001.geometry}
            material={materials.Frames_Frame_mat}
          />
          <mesh
            geometry={nodes.Frames_Portrait_Support_001.geometry}
            material={materials._Black}
          />
          <mesh
            geometry={nodes.Frames_Portrait_Image_001.geometry}
            material={clonedMaterial}
          />
        </Select>
      )}
    </group>
  );
}

useGLTF.preload('/3d/Frames/Frames.gltf');

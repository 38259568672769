/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 assets/3d/DoorSkirtings/Door_Skirtings.gltf
Created: Sun Jun 11 23:25:41 2023
*/

import { useGLTF, useTexture } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import React from 'react';

import { ExtendedGLTF } from '../../../types';
import ModelWrapper from './ModelWrapper';

export default function Model() {
  const { nodes, materials } = useGLTF(
    '/3d/DoorSkirtings/Door_Skirtings.gltf',
  ) as ExtendedGLTF;
  const lightMap = useTexture('/3d/DoorSkirtings/Door_Skirtings_Lightmap.jpg');
  const { scene } = useThree();

  materials.Door_Skirtings_mat.lightMap = lightMap;
  materials.Door_Skirtings_mat.lightMapIntensity = 4;
  materials.Door_Skirtings_mat.lightMap.flipY = false;
  materials.Door_Skirtings_mat.envMap = scene.background;
  materials.Door_Skirtings_mat.envMapIntensity = 6;
  materials.Door_Skirtings_mat.aoMap = lightMap;

  return (
    <ModelWrapper>
      <mesh
        geometry={nodes.Door_Skirtings.geometry}
        material={materials.Door_Skirtings_mat}
        castShadow
        receiveShadow
      />
    </ModelWrapper>
  );
}

useGLTF.preload(
  '/3d/DoorSkirtings/Door_Skirtings.gltf',
  '/3d/DoorSkirtings/Door_Skirtings_Lightmap.jpg',
);

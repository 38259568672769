/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 assets/3d/Desk/Desk.gltf
Created: Wed May 31 15:57:27 2023
*/

import { useGLTF, useTexture } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import React from 'react';

import { ExtendedGLTF } from '../../../types';
import ModelWrapper from './ModelWrapper';

export default function Model() {
  const { nodes, materials } = useGLTF('/3d/Desk/Desk.gltf') as ExtendedGLTF;
  const lightMap = useTexture('/3d/Desk/Desk_Lightmap.jpg');
  const { scene } = useThree();

  materials.Desk_mat.lightMap = lightMap;
  materials.Desk_mat.lightMapIntensity = 3;
  materials.Desk_mat.lightMap.flipY = false;
  materials.Desk_mat.envMap = scene.background;
  materials.Desk_mat.envMapIntensity = 3;
  return (
    <ModelWrapper>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Desk.geometry}
        material={materials.Desk_mat}
      />
    </ModelWrapper>
  );
}

useGLTF.preload(['/3d/Desk/Desk.gltf', '/3d/Desk/Desk_Lightmap.jpg']);

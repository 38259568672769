/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 assets/3d/Microphone/Microphone.gltf
Created: Mon Jun  5 16:26:25 2023
*/

import { useGLTF, useTexture } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import React from 'react';

import { ExtendedGLTF } from '../../../types';
import ModelWrapper from './ModelWrapper';

export default function Model() {
  const { nodes, materials } = useGLTF(
    '/3d/Microphone/Microphone.gltf',
  ) as ExtendedGLTF;
  const lightMap = useTexture('/3d/Microphone/Microphone_Lightmap.jpg');
  const { scene } = useThree();

  materials.Microphone_mat.lightMap = lightMap;
  materials.Microphone_mat.lightMapIntensity = 2;
  materials.Microphone_mat.lightMap.flipY = false;
  materials.Microphone_mat.envMap = scene.background;
  materials.Microphone_mat.envMapIntensity = 3;

  return (
    <ModelWrapper>
      <mesh
        geometry={nodes.Microphone.geometry}
        material={materials.Microphone_mat}
        castShadow
        receiveShadow
      />
    </ModelWrapper>
  );
}

useGLTF.preload([
  '/3d/Microphone/Microphone.gltf',
  '/3d/Microphone/Microphone_Lightmap.jpg',
]);

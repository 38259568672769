import React from 'react';
import { ActivityIndicator, Image, StyleSheet, View } from 'react-native';

import { Api } from '../../api';
import { LRText, Spacer } from '../../components';
import { Color, Style } from '../../constants';
import { Model } from '../../models';

type Props = {
  media: Api.Album.V_Detail['albumMedias'][0];
};

export default function MediaView({ media }: Props) {
  let videoEncoding = null;
  if (Model.Upload.isVideo(media.upload)) {
    videoEncoding =
      media.upload.videoEncodings?.[media.upload.videoEncodings.length - 1];
  }
  return (
    <>
      {Model.Upload.isVideo(media.upload) && media.upload.videoUrlTemplate ? (
        <>
          {videoEncoding ? (
            <video
              controls
              src={media.upload.videoUrlTemplate.replace(
                '__ENCODING__',
                videoEncoding.toString(),
              )}
              style={styles.video}
            />
          ) : (
            <View style={[Style.centerBoth]}>
              <ActivityIndicator color={Color.accent} size="large" />
              <Spacer />
              <LRText textAlign="center" color="gray">
                This video is still being processed, check back later
              </LRText>
            </View>
          )}
        </>
      ) : (
        <Image
          source={media.upload.pathOrganiserCover as any}
          resizeMode="contain"
          style={styles.image}
        />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  image: {
    width: '100%',
    flex: 1,
    maxHeight: 450,
  },
  video: {
    maxHeight: 450,
    width: '100%',
    backgroundColor: Color.black,
  },
});

import {
  EffectComposer,
  Outline,
  Selection,
} from '@react-three/postprocessing';
import { KernelSize } from 'postprocessing';
import * as React from 'react';

type Props = {
  children: React.ReactNode;
};

export default function HoverOutline({ children }: Props) {
  return (
    <Selection>
      <EffectComposer multisampling={8} autoClear={false}>
        <Outline
          blur
          visibleEdgeColor={0xffffff}
          hiddenEdgeColor={0xffffff}
          edgeStrength={6}
          kernelSize={KernelSize.LARGE}
        />
      </EffectComposer>
      {children}
    </Selection>
  );
}

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 assets/3d/Env/Env.gltf
*/

import { useGLTF, useTexture } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import React from 'react';

import { ExtendedGLTF } from '../../../types';
import ModelWrapper from './ModelWrapper';

export default function Model() {
  const { nodes, materials } = useGLTF('/3d/Env/Env.gltf') as ExtendedGLTF;
  const lightMap = useTexture('/3d/Env/Env_Lightmap.jpg');
  const { scene } = useThree();

  materials.ENV1.lightMap = lightMap;
  materials.ENV1.lightMapIntensity = 1.2;
  materials.ENV1.lightMap.flipY = false;
  materials.ENV1.envMap = scene.background;
  materials.ENV1.envMapIntensity = 0;

  return (
    <ModelWrapper>
      <mesh
        geometry={nodes.ENV.geometry}
        material={materials.ENV1}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.01}
      />
    </ModelWrapper>
  );
}

useGLTF.preload(['/3d/Env/Env.gltf', '/3d/Env/Env_Lightmap.jpg']);

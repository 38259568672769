/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 assets/3d/Lamps/Lamps.gltf
Created: Wed May 31 10:43:32 2023
*/

import { useGLTF, useTexture } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import React from 'react';

import { ExtendedGLTF } from '../../../types';
import { ModelWrapper } from '.';

export default function Model() {
  const { nodes, materials } = useGLTF('/3d/Lamps/Lamps.gltf') as ExtendedGLTF;
  const lightMap = useTexture('/3d/Lamps/Lamps_Lightmap.jpg');
  const { scene } = useThree();

  materials.Lamps_mat.lightMap = lightMap;
  materials.Lamps_mat.lightMapIntensity = 2;
  materials.Lamps_mat.lightMap.flipY = false;
  materials.Lamps_mat.envMap = scene.background;
  materials.Lamps_mat.envMapIntensity = 5;

  return (
    <ModelWrapper>
      <mesh
        geometry={nodes.Lamps.geometry}
        material={materials.Lamps_mat}
        castShadow
        receiveShadow
      />
    </ModelWrapper>
  );
}

useGLTF.preload(['/3d/Lamps/Lamps.gltf', '/3d/Lamps/Lamps_Lightmap.jpg']);

import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  alignCenter: { alignItems: 'center' },
  alignStart: { alignItems: 'flex-start' },

  centerBoth: { alignItems: 'center', justifyContent: 'center' },

  disabled: { opacity: 0.38 },

  fullWidth: { width: '100%' },

  fill: { flex: 1 },

  justifyCenter: { justifyContent: 'center' },

  row: { flexDirection: 'row' },

  spaceBetween: { justifyContent: 'space-between' },
});

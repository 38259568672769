/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 assets/3d/Pots/Pots.gltf
Created: Sun Jun 11 21:50:48 2023
*/

import { useGLTF, useTexture } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import React from 'react';

import { ExtendedGLTF } from '../../../types';
import ModelWrapper from './ModelWrapper';

export default function Model() {
  const { nodes, materials } = useGLTF('/3d/Pots/Pots.gltf') as ExtendedGLTF;
  const lightMap = useTexture('/3d/Pots/Pots_Lightmap.jpg');
  const { scene } = useThree();

  materials.Pots_mat.lightMap = lightMap;
  materials.Pots_mat.lightMapIntensity = 4;
  materials.Pots_mat.lightMap.flipY = false;
  materials.Pots_mat.envMap = scene.background;
  materials.Pots_mat.envMapIntensity = 5;

  return (
    <ModelWrapper>
      <mesh
        geometry={nodes.Pots.geometry}
        material={materials.Pots_mat}
        castShadow
        receiveShadow
      />
    </ModelWrapper>
  );
}

useGLTF.preload(['/3d/Pots/Pots.gltf', '/3d/Pots/Pots_Lightmap.jpg']);

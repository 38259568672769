import { useApiRead } from 'api-read-hook';
import * as React from 'react';
import { ActivityIndicator, View } from 'react-native';

import { Api, ApiError } from '../api';
import { LRText, PaddedArea, ScreenError, Spacer } from '../components';
import { Color, Style } from '../constants';

const VisitorDataContext = React.createContext<{
  entryData: Api.User.V_VisitorEntry;
  visitorData: Api.User.V_Visitor | undefined;
  setPin: (pin: string) => void;
}>({
  entryData: undefined as any,
  setPin: () => {},
  visitorData: undefined,
});

type Props = {
  children: React.ReactNode;
};

export function VisitorDataContextProvider({ children }: Props) {
  const roomSlug = window.location.pathname.substring(1).split('/')[0];
  if (!roomSlug || roomSlug.length === 0) {
    return (
      <PaddedArea h={2} v={2} style={[Style.fill, Style.centerBoth]}>
        <LRText typeface="h2" textAlign="center">
          Legacy Room not found
        </LRText>
        <Spacer />
        <LRText textAlign="center" color="gray7">
          Check the link you followed contains /username.
        </LRText>
      </PaddedArea>
    );
  }

  const apiEntry = Api.User.useReadItemVisitorEntry(roomSlug);

  const [pin, setPin] = React.useState<string | null>(null);
  let visitorUrl = null;
  if (apiEntry.data) {
    if (!apiEntry.data.hasRoomPin) {
      visitorUrl = `/users/${roomSlug}/visitor`;
    } else {
      visitorUrl = pin ? `/users/${roomSlug}/visitor?pin=${pin}` : null;
    }
  }
  const apiVisitor = useApiRead<Api.User.V_Visitor>(visitorUrl);

  if (
    apiEntry.error &&
    apiEntry.error instanceof ApiError &&
    apiEntry.error.response.status === 404
  ) {
    return (
      <PaddedArea h={2} v={2} style={[Style.fill, Style.centerBoth]}>
        <LRText typeface="h2" textAlign="center">
          No Legacy Room found for "{roomSlug}"
        </LRText>
        <Spacer />
        <LRText textAlign="center" color="gray7">
          Please check you typed the link correctly.
        </LRText>
      </PaddedArea>
    );
  }
  if (apiEntry.error) return <ScreenError {...apiEntry} />;
  if (!apiEntry.data) {
    return (
      <View style={[Style.fill, Style.centerBoth]}>
        <ActivityIndicator color={Color.lightGrey} size="large" />
      </View>
    );
  }

  if (apiVisitor.error) return <ScreenError {...apiVisitor} />;

  return (
    <VisitorDataContext.Provider
      value={{ entryData: apiEntry.data, setPin, visitorData: apiVisitor.data }}
    >
      {children}
    </VisitorDataContext.Provider>
  );
}

export function useVisitorDataContext() {
  return React.useContext(VisitorDataContext);
}

import { LinearGradient } from 'expo-linear-gradient';
import { ImageBackground, StyleSheet, View } from 'react-native';

import { Api } from '../../api';
import { Button, LRText, PaddedArea, Spacer } from '../../components';
import { Color } from '../../constants';
import { useLayoutContext, useScreenRouterContext } from '../../contexts';

type Props = {
  friendship: NonNullable<Api.Friendship.V_UserSub>;
};

export default function FriendListItem({ friendship }: Props) {
  const { isDesktopLayout } = useLayoutContext();
  const { showScreen } = useScreenRouterContext();
  const itemWidth = isDesktopLayout
    ? // 2/3 of 40px
      'calc(33% - 26px)'
    : '100%';

  return (
    <View
      style={[
        styles.container,
        { minWidth: itemWidth, width: itemWidth, maxWidth: itemWidth },
      ]}
    >
      <LinearGradient
        colors={['#00000000', '#000000CC']}
        style={styles.linearGradient}
      >
        <PaddedArea style={styles.contentContainer} v={1.5} h={1.2}>
          <Spacer fill />
          <LRText color="whiteHigh" typeface="h1" textAlign="center">
            {`${friendship.friend.firstName} ${friendship.friend.lastName}`}
          </LRText>
          <Spacer size={1.5} />
          <Button
            title="Explore"
            buttonType="hollowWhite"
            onPress={() => {
              showScreen(['Album', { albumId: friendship.album!.id }]);
            }}
          />
        </PaddedArea>
      </LinearGradient>
      <ImageBackground
        resizeMode="cover"
        style={styles.backgroundImage}
        source={friendship.friend?.avatar?.pathOrganiserCover as any}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    aspectRatio: '354/300',
    backgroundColor: Color.darkBg,
    borderRadius: 32,
    overflow: 'hidden',
  },
  linearGradient: {
    height: '100%',
    width: '100%',
  },
  contentContainer: {
    flex: 1,
    alignItems: 'center',
  },
  backgroundImage: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: -1,
  },
});

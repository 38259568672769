import * as React from 'react';
import { Pressable, StyleSheet, View } from 'react-native';

import { IconCrossBig } from '../../components/icons';
import { Color } from '../../constants';

type Props = {
  onPress: () => void;
};

export default function BackspaceButton({ onPress }: Props) {
  const buttonSize = 78;
  return (
    <View
      style={{
        width: buttonSize,
        height: buttonSize,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Pressable style={styles.button} onPress={onPress}>
        {/* @ts-ignore complains hovered does't exist but works fine */}
        {({ hovered, pressed }) => (
          <>
            <View
              style={[
                styles.arrow,
                hovered && { borderRightColor: Color.whiteMedium },
                pressed && { borderRightColor: Color.whiteDisabled },
              ]}
            />
            <View
              style={[
                styles.box,
                hovered && { backgroundColor: Color.whiteMedium },
                pressed && { backgroundColor: Color.whiteDisabled },
              ]}
            >
              <IconCrossBig size={28} color={Color.black} />
            </View>
          </>
        )}
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  button: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  box: {
    backgroundColor: Color.whiteHigh,
    height: 32,
    width: 32,
    alignItems: 'center',
    justifyContent: 'center',
    transitionProperty: 'background-color',
    transitionDuration: '100ms',
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  arrow: {
    width: 0,
    height: 0,
    borderTopWidth: 16,
    borderBottomWidth: 16,
    borderTopColor: 'transparent',
    borderBottomColor: 'transparent',
    borderRightWidth: 16,
    borderRightColor: Color.whiteHigh,
    transitionProperty: 'border-right-color',
    transitionDuration: '100ms',
  },
});

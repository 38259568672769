/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 assets/3d/Tables/Tables.gltf
Created: Sun Jun 11 20:15:37 2023
*/

import { useGLTF, useTexture } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import React from 'react';

import { ExtendedGLTF } from '../../../types';
import ModelWrapper from './ModelWrapper';

export default function Model() {
  const { nodes, materials } = useGLTF(
    '/3d/Tables/Tables.gltf',
  ) as ExtendedGLTF;
  const lightMap = useTexture('/3d/Tables/Tables_Lightmap.jpg');
  const { scene } = useThree();

  materials.Tables_metall_mat.lightMap = lightMap;
  materials.Tables_metall_mat.lightMapIntensity = 0;
  materials.Tables_metall_mat.lightMap.flipY = false;
  materials.Tables_metall_mat.envMap = scene.background;
  materials.Tables_metall_mat.envMapIntenisty = 2;

  materials.Table_glass_mat.lightMap = lightMap;
  materials.Table_glass_mat.lightMapIntensity = 1;
  materials.Table_glass_mat.lightMap.flipY = false;
  materials.Table_glass_mat.envMap = scene.background;
  materials.Table_glass_mat.envMapIntensity = 8;

  return (
    <ModelWrapper>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Tables_metall.geometry}
        material={materials.Tables_metall_mat}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Tables_glass.geometry}
        material={materials.Table_glass_mat}
      />
    </ModelWrapper>
  );
}

useGLTF.preload(['/3d/Tables/Tables.gltf', '/3d/Tables/Tables_Lightmap.jpg']);

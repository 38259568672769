import * as React from 'react';
import { Pressable, StyleProp, StyleSheet, ViewStyle } from 'react-native';

import { Color, Dimen, Style } from '../constants';
import LRText from './LRText';
import Spacer from './Spacer';

export type ButtonType =
  | 'hollowAccent'
  | 'hollowWhite'
  | 'white'
  | 'primary'
  | 'red900'
  | 'tertiary'
  | 'text';

export type ButtonPadding = 'large' | 'regular' | 'small' | 'none';

const getButtonPadding = (buttonSize: ButtonPadding): StyleProp<ViewStyle> => {
  switch (buttonSize) {
    case 'large':
      return {
        paddingVertical: Dimen.spacing,
        paddingHorizontal: Dimen.spacing * 4,
      };
    case 'small':
      return {
        paddingVertical: Dimen.spacing * 0.5,
        paddingHorizontal: Dimen.spacing * 0.5,
      };
    case 'none':
      return {
        padding: 0,
      };
    default:
    case 'regular':
      return {
        paddingVertical: Dimen.spacing,
        paddingHorizontal: Dimen.spacing * 1,
      };
  }
};

const getBackgroundColor = (buttonType: ButtonType): StyleProp<ViewStyle> => {
  switch (buttonType) {
    case 'white':
      return { backgroundColor: Color.whiteHigh };
    case 'hollowWhite':
      return {
        backgroundColor: 'transparent',
        borderWidth: 1,
        borderColor: Color.whiteHigh,
      };
    case 'tertiary':
      return { backgroundColor: Color.lightGrey };
    case 'hollowAccent':
      return {
        backgroundColor: 'transparent',
        borderWidth: 1,
        borderColor: Color.accent,
      };
    case 'red900':
      return { backgroundColor: Color.red900 };
    case 'text':
      return { backgroundColor: 'transparent', borderColor: 'transparent' };
    case 'primary':
    default:
      return { backgroundColor: Color.accent };
  }
};

const getHoveredStyle = (buttonType: ButtonType): StyleProp<ViewStyle> => {
  switch (buttonType) {
    case 'hollowAccent':
      return {
        backgroundColor: Color.accent,
        borderColor: Color.accent,
      };
    case 'hollowWhite':
      return {
        backgroundColor: Color.whiteHigh,
        borderColor: Color.whiteHigh,
      };
    case 'white':
      return {
        backgroundColor: Color.whiteMedium,
      };
    case 'primary':
      return { backgroundColor: Color.accentHovered };
    case 'tertiary':
      return { backgroundColor: Color.lightGreyHovered };
    default:
      return null;
  }
};

const getFontColor = (buttonType: ButtonType): keyof typeof Color => {
  switch (buttonType) {
    case 'white':
    case 'hollowAccent':
      return 'accent';
    case 'tertiary':
      return 'accent';
    case 'text':
      return 'whiteMedium';
    default:
      return 'whiteHigh';
  }
};

const getHoveredFontColor = (buttonType: ButtonType): keyof typeof Color => {
  switch (buttonType) {
    case 'hollowWhite':
      return 'darkBg';
    case 'white':
      return 'accent';
    case 'tertiary':
      return 'accent';
    case 'text':
      return 'whiteMedium';
    default:
      return 'whiteHigh';
  }
};

type Props = {
  title?: string;
  onPress: () => void;
  icon?: React.ReactNode;
  disabled?: boolean;
  buttonType?: ButtonType;
  buttonPadding?: ButtonPadding;
};

export default function Button({
  title,
  onPress,
  icon,
  disabled = false,
  buttonType = 'primary',
  buttonPadding = 'regular',
}: Props) {
  return (
    <Pressable
      disabled={disabled}
      onPress={onPress}
      style={({ hovered }: any) => [
        styles.button,
        getButtonPadding(buttonPadding),
        getBackgroundColor(buttonType),
        hovered && !disabled && getHoveredStyle(buttonType),
        disabled && Style.disabled,
      ]}
    >
      {({ hovered }: any) => (
        <>
          {title && (
            <LRText
              typeface="button"
              color={
                hovered
                  ? getHoveredFontColor(buttonType)
                  : getFontColor(buttonType)
              }
            >
              {title}
            </LRText>
          )}
          {icon && (
            <>
              {title && <Spacer size={0.5} />}
              {icon}
            </>
          )}
        </>
      )}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  button: {
    flexDirection: 'row',
    borderRadius: Dimen.cornerRadiusLarge,
    alignItems: 'center',
    justifyContent: 'center',
    transitionDuration: '200ms',
  },
});

import { useProgress } from '@react-three/drei';
import * as React from 'react';
import { Pressable, StyleSheet, View } from 'react-native';

import { ApiError, useApiCallable } from '../../api';
import { Button, LRText, PaddedArea, Spacer } from '../../components';
import { Color, Style } from '../../constants';
import { useScreenRouterContext, useVisitorDataContext } from '../../contexts';
import BackspaceButton from './BackspaceButton';

export default function Keypad() {
  const { dismissScreen } = useScreenRouterContext();
  const { entryData, setPin, visitorData } = useVisitorDataContext();

  const callApi = useApiCallable();
  const { progress } = useProgress();

  const [input, setInput] = React.useState<string>('');
  const [loading, setLoading] = React.useState(false);
  const [wasCorrect, setWasCorrect] = React.useState(false);
  const [wasIncorrect, setWasIncorrect] = React.useState(false);

  React.useEffect(() => {
    if (wasCorrect && visitorData) {
      setTimeout(() => {
        dismissScreen();
      }, 100);
    }
  }, [visitorData, wasCorrect]);

  const pinLength = entryData.roomPinLength;
  if (!pinLength) {
    throw new Error(`Called keypad but no pin length for ${entryData['@id']}`);
  }

  function pressKey(num: number) {
    if (input.length === pinLength) return;
    setInput(`${input}${num}`);

    setWasIncorrect(false);
  }

  return (
    <PaddedArea v={3} h={3.5} style={styles.container}>
      <View style={Style.alignCenter}>
        <LRText typeface="h3" color="whiteHigh">
          Enter code
        </LRText>
        {wasIncorrect && (
          <>
            <Spacer size={0.25} />
            <LRText typeface="body1" color="red400">
              Wrong code. Please try again.
            </LRText>
          </>
        )}
      </View>
      <Spacer size={1.5} />
      <View style={Style.row}>
        {new Array(pinLength).fill(1).map((_, i) => (
          <View
            style={[styles.inputDot, input.length > i && styles.inputDotFill]}
            key={i}
          />
        ))}
      </View>
      <Spacer size={1.5} />
      { progress === 100 && (
      <View style={styles.buttons}>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((num) => (
          <React.Fragment key={num}>
            {num === 0 && <View style={styles.slot} />}
            <View style={styles.slot}> 
              <Pressable
                onPress={() => pressKey(num)}
                // @ts-ignore complains hovered does't exist but works fine
                style={({ hovered, pressed }) => [
                  styles.button,
                  hovered && styles.buttonHovered,
                  pressed && styles.buttonPressed,
                ]}
              >
                <LRText typeface="titleS" color="accent400" selectable={false}>
                  {num}
                </LRText>
              </Pressable>
            </View>
            {num === 0 && (
              <View style={styles.slot}>
                <BackspaceButton
                  onPress={() =>
                    setInput(input.substring(0, Math.max(0, input.length - 1)))
                  }
                />
              </View>
            )}
          </React.Fragment>
        ))}
      </View>
      )}
      <Spacer size={1.5} />
      <View style={Style.fullWidth}>
        <Button
          buttonType={progress < 100 ? 'hollowWhite' : 'white'}
          title={
            progress < 100
              ? `Loading ${Math.floor(progress)} / 100`
              : 'Enter Room'
          }
          disabled={
            loading ||
            wasCorrect ||
            progress < 100 ||
            input.length !== pinLength
          }
          onPress={async () => {
            setLoading(true);

            const response = await callApi(
              `/users/${entryData.roomSlug}/visitor?pin=${input}`,
              undefined,
              {
                errorHandler: (err, originalHandler) => {
                  if (err instanceof ApiError && err.response?.status === 403) {
                    // No action needed
                    return;
                  } else {
                    originalHandler(err);
                  }
                },
              },
            );
            if (response) {
              setWasCorrect(true);
              setPin(input);
            } else {
              setWasIncorrect(true);
              setTimeout(() => setInput(''), 100);
            }
            setLoading(false);
          }}
        />
      </View>
    </PaddedArea>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Color.accent400,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 22,
  },
  buttons: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginVertical: -12,
    marginHorizontal: -9,
    width: 78 * 3 + 12 * 6,
  },
  slot: {
    marginHorizontal: 12,
    marginVertical: 9,
    width: 78,
  },
  button: {
    width: 78,
    height: 78,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Color.whiteHigh,
    borderRadius: 39,
    transitionProperty: 'background-color',
    transitionDuration: '100ms',
  },
  buttonHovered: {
    backgroundColor: Color.whiteMedium,
  },
  buttonPressed: {
    backgroundColor: Color.whiteDisabled,
  },
  inputDot: {
    marginHorizontal: 7,
    width: 18,
    height: 18,
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    borderRadius: 9,
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputDotFill: {
    backgroundColor: Color.whiteHigh,
  },
});

import * as React from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';

import { Color, Dimen, Style } from '../constants';
import { useScreenRouterContext } from '../contexts';
import { useMovementContext } from '../pods/room/contexts';
import IconButton from './IconButton';
import { IconCrossBig } from './icons';

export default function ScreenLoading() {
  const { popBack } = useMovementContext();
  const { dismissScreen } = useScreenRouterContext();

  function onDismiss() {
    dismissScreen();
    setTimeout(() => {
      popBack();
    }, 100);
  }

  return (
    <View style={[StyleSheet.absoluteFill, styles.container]}>
      <View style={[Style.fill, Style.centerBoth]}>
        <ActivityIndicator size="large" color={Color.whiteMedium} />
      </View>
      <View style={styles.closeButton}>
        <IconButton
          size={70}
          onPress={onDismiss}
          icon={IconCrossBig}
          iconSize={42}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#000a',
    // @ts-ignore
    cursor: 'default',
  },
  closeButton: {
    position: 'absolute',
    top: Dimen.spacing * 0.5,
    right: Dimen.spacing,
  },
});

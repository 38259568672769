import * as React from 'react';

import { RootCanvas } from '../pods/room';
import ScreenRouter from './ScreenRouter';

export default function App() {
  return (
    <>
      <RootCanvas />
      <ScreenRouter />
    </>
  );
}

import * as React from 'react';
import { ColorValue, Pressable, StyleSheet } from 'react-native';

import { Color } from '../constants';

type Props = {
  onPress: () => void;
  icon: (props: { color: string; size: number }) => React.ReactNode;
  size?: number;
  iconSize?: number;
  hoverColor?: ColorValue;
  pressedColor?: ColorValue;
};

export default function IconButton({
  onPress,
  icon,
  size = 48,
  iconSize = 16,
  hoverColor = '#fff1',
  pressedColor = '#fff3',
}: Props) {
  return (
    <Pressable
      // @ts-ignore
      style={({ hovered, pressed }) => [
        styles.button,
        { width: size, height: size },
        hovered && { backgroundColor: hoverColor },
        pressed && { backgroundColor: pressedColor },
      ]}
      onPress={onPress}
    >
      {icon({ color: Color.whiteHigh, size: iconSize })}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  button: {
    borderRadius: 42,
    alignItems: 'center',
    justifyContent: 'center',
    transitionProperty: 'background-color',
    transitionDuration: '150ms',
  },
});

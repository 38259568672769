import { OrbitControls } from '@react-three/drei';
import * as React from 'react';
import * as THREE from 'three';

const CameraContext = React.createContext<{
  cameraRef: React.Ref<THREE.PerspectiveCamera>;
  orbitRef: React.Ref<typeof OrbitControls>;
  translateBy: (vec: THREE.Vector3) => void;
}>({
  cameraRef: undefined as any,
  orbitRef: undefined as any,
  translateBy: () => {},
});

type Props = {
  children: React.ReactNode;
};

export function CameraContextProvider({ children }: Props) {
  const cameraRef = React.useRef<THREE.PerspectiveCamera>();
  const orbitRef = React.useRef<typeof OrbitControls>();

  const translateBy = React.useCallback((vec: THREE.Vector3) => {
    if (!cameraRef.current) {
      console.warn('translateBy: no cameraRef.current');
      return;
    }
    if (!orbitRef.current) {
      console.warn('translateBy: no orbitRef.current');
      return;
    }
    cameraRef.current.position.x += vec.x;
    cameraRef.current.position.y += vec.y;
    cameraRef.current.position.z += vec.z;
    orbitRef.current.target.x += vec.x;
    orbitRef.current.target.y += vec.y;
    orbitRef.current.target.z += vec.z;
  }, []);

  return (
    <CameraContext.Provider
      value={{
        cameraRef,
        orbitRef,
        translateBy,
      }}
    >
      {children}
    </CameraContext.Provider>
  );
}

export function useCameraContext() {
  return React.useContext(CameraContext);
}

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 assets/3d/Mirror/Mirror.gltf
Created: Wed May 31 19:26:38 2023
*/

import { useGLTF, useTexture } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import React from 'react';

import { ExtendedGLTF } from '../../../types';
import ModelWrapper from './ModelWrapper';

export default function Model() {
  const { nodes, materials } = useGLTF(
    '/3d/Mirror/Mirror.gltf',
  ) as ExtendedGLTF;
  const lightMap = useTexture('/3d/Mirror/Mirror_Lightmap.jpg');
  const { scene } = useThree();

  materials.Mirror_mat.lightMap = lightMap;
  materials.Mirror_mat.lightMapIntensity = 4;
  materials.Mirror_mat.lightMap.flipY = false;
  materials.Mirror_mat.envMap = scene.background;
  materials.Mirror_mat.envMapIntensity = 4;

  return (
    <ModelWrapper>
      <mesh
        geometry={nodes.Mirror.geometry}
        material={materials.Mirror_mat}
        castShadow
        receiveShadow
      />
    </ModelWrapper>
  );
}

useGLTF.preload(['/3d/Mirror/Mirror.gltf', '/3d/Mirror/Mirror_Lightmap.jpg']);

import { useFrame } from '@react-three/fiber';

import { useCameraContext } from '../contexts';
import { useMovementContext } from '../contexts/MovementContext';

export default function useCameraMovement() {
  const { orbitRef, translateBy } = useCameraContext();
  const {
    currentAnimVector,
    currentAnimStartDistance,
    desiredTargetPoint,
    setMovementFinished,
  } = useMovementContext();

  useFrame((_state, delta) => {
    if (!currentAnimVector) return;

    const dist = orbitRef.current.target.distanceTo(desiredTargetPoint);
    if (dist < 0.1) {
      setMovementFinished();
      return;
    }

    // How far from either start or end?
    const betweenDist = Math.min(
      dist,
      Math.max(0, (currentAnimStartDistance ?? 0) - dist),
    );

    // Meters per second
    const moveSpeed = 1.5 + betweenDist * 2;
    const moveAmount = Math.min(
      moveSpeed * delta,
      // Make sure not to overshoot
      dist,
    );

    translateBy(currentAnimVector.clone().multiplyScalar(moveAmount));
  });
}

import { useLoader, useThree } from '@react-three/fiber';
import * as React from 'react';
import * as THREE from 'three';

export default function useEnvMap() {
  const { gl, scene } = useThree();

  const [cubeMap] = useLoader(
    // @ts-ignore
    THREE.CubeTextureLoader,
    [['px.jpg', 'nx.jpg', 'py.jpg', 'ny.jpg', 'pz.jpg', 'nz.jpg']],
    (loader) => {
      loader.setPath('/envmap/');
    },
  );

  const cubeMapRef = React.useRef(cubeMap);

  React.useEffect(() => {
    const gen = new THREE.PMREMGenerator(gl);
    gen.compileEquirectangularShader();
    const hdrCubeRenderTarget = gen.fromCubemap(cubeMap);
    cubeMap.dispose();
    gen.dispose();
    scene.background = hdrCubeRenderTarget.texture;
    scene.environment = hdrCubeRenderTarget.texture;
    cubeMapRef.current = hdrCubeRenderTarget.texture;
    return () => {
      scene.environment = null;
      scene.background = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cubeMap]);
}

import { useTexture } from '@react-three/drei';
import * as React from 'react';

export default function WalkIndicatorCircle() {
  const textures = useTexture({
    color: '3d/walk-indicator.png',
    alpha: '3d/walk-indicator-alpha.png',
  });

  return (
    <mesh rotation={[-Math.PI / 2, 0, 0]}>
      <planeGeometry args={[0.5, 0.5]} />
      <meshPhongMaterial
        transparent
        map={textures.color}
        emissive={0xffffff}
        emissiveIntensity={5}
      />
    </mesh>
  );
}

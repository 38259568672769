import { Text as ThreeText, useGLTF } from '@react-three/drei';
import { Euler, Vector3 } from '@react-three/fiber';
import React from 'react';

const getAdjustedFont = (
  text: string,
  fontSize: number,
  maxLineLength: number,
  multiline: boolean,
) => {
  if (text.length < maxLineLength / 2) {
    return fontSize * 1.25;
  }

  if (multiline && text.length >= maxLineLength) {
    return fontSize * 0.7;
  }

  return fontSize;
};

const splitLineIfLong = (
  text: string,
  maxLineLength: number,
  enabled?: boolean,
) => {
  let formattedText = text;
  const maxTotalLength = enabled ? maxLineLength * 2 : maxLineLength;
  if (formattedText.length > maxTotalLength) {
    formattedText = formattedText.slice(0, maxTotalLength - 3).concat('...');
  }

  if (enabled && formattedText.length > maxLineLength) {
    const words = formattedText.split(' ');
    const halfLength = Math.ceil(words.length / 2);

    let line1 = '';
    let line2 = '';

    for (let i = 0; i < words.length; i++) {
      if (i < halfLength) {
        line1 += words[i] + ' ';
      } else {
        line2 += words[i] + ' ';
      }
    }
    return `${line1.trim()}\n${line2.trim()}`;
  }
  return formattedText;
};

type Props = {
  text: string;
  /**
   * Center position
   */
  position: Vector3;
  maxLineLength: number;
  fontSize: number;
  rotation?: Euler;
  /**
   * Allow splitting onto 2 lines
   */
  multiline?: boolean;
  color?: string;
};

export default function Text({
  text,
  position,
  maxLineLength,
  fontSize,
  rotation = [0, Math.PI / 2, 0],
  multiline = false,
  color,
}: Props) {
  return (
    <ThreeText
      position={position}
      rotation={rotation}
      fontSize={getAdjustedFont(text, fontSize, maxLineLength, multiline)}
      color={color ? color : '#f5d282'}
      textAlign="center"
    >
      {splitLineIfLong(text, maxLineLength, multiline)}
    </ThreeText>
  );
}

useGLTF.preload('/3d/Books/Books.gltf', '/3d/Books/Books_Lightmap.jpg');

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 assets/3d/Mac/Mac.gltf
Created: Sun Jun 11 21:33:02 2023
*/

import { Plane, Text, useGLTF, useTexture } from '@react-three/drei';
import React, { useState } from 'react';
import { ColorExtractor } from 'react-color-extractor';

import { Api } from '../../../../api';
import { useCroppedTexture } from '../../hooks';

type Props = {
  album: Api.User.V_Visitor['roomTriggers'][0];
  title: string;
};

export default function MacCover({ album, title }: Props) {
  const [backgroundColor, setBackgroundColor] = useState<string | undefined>(
    undefined,
  );

  return (
    <>
      <Plane
        args={[0.45, 0.45, 1]} // Width, height, segments
        position={[4.44, 1.38, 2.35]} // Adjust position as needed
        rotation={[0, 150.3 * (Math.PI / 180), 0]} // Rotate the plane to face the camera
      >
        <meshBasicMaterial
          attach="material"
          map={useCroppedTexture({
            sourceUrl: album.album!.safeCover!.upload.pathOrganiserCover!,
            sourceDimensions: album.album!.safeCover!.upload.file.dimensions!,
            targetAspectRatio: 1,
          })}
        />
      </Plane>
      <Plane
        args={[0.45, 0.45, 1]} // Width, height, segments
        position={[4.05, 1.38, 2.13]} // Adjust position as needed
        rotation={[0, 150.3 * (Math.PI / 180), 0]} // Rotate the plane to face the camera
      >
        <Text position={[0, 0, 0.01]} textAlign="center" maxWidth={0.45}>
          {title}
        </Text>
        <meshBasicMaterial attach="material" color={backgroundColor} />
      </Plane>
      <ColorExtractor
        src={album.album?.safeCover?.upload.pathOrganiserCover}
        getColors={(colors: string[]) => {
          if (colors) {
            setBackgroundColor(colors[1]);
          }
        }}
      />
    </>
  );
}

useGLTF.preload('/3d/Mac/Mac.gltf', '/3d/Mac/Mac_Lightmap.jpg');

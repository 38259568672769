import {
  DMSans_400Regular,
  DMSans_500Medium,
  DMSans_700Bold,
  useFonts,
} from '@expo-google-fonts/dm-sans';
import { DMSerifDisplay_400Regular } from '@expo-google-fonts/dm-serif-display';
import * as React from 'react';
import { ActivityIndicator, View } from 'react-native';
import { Provider as ReduxProvider } from 'react-redux';
import { createStore } from 'redux';

import { LRApiReadConfig } from '../api';
import {
  LayoutContextProvider,
  ScreenRouterContextProvider,
  VisitorDataContextProvider,
} from '../contexts';
import {
  CameraContextProvider,
  MovementContextProvider,
} from '../pods/room/contexts';
import App from './App';

const store = createStore((state) => state);

export default function AppLoader() {
  const [fontsLoaded] = useFonts({
    DMSans_400Regular,
    DMSans_500Medium,
    DMSans_700Bold,
    DMSerifDisplay_400Regular,
  });

  if (!fontsLoaded) {
    return (
      <View style={{ flex: 1, justifyContent: 'center' }}>
        <ActivityIndicator style={{ alignSelf: 'center' }} />
      </View>
    );
  }

  return (
    <ReduxProvider store={store}>
      <LayoutContextProvider>
        <LRApiReadConfig>
          <VisitorDataContextProvider>
            <ScreenRouterContextProvider>
              <CameraContextProvider>
                <MovementContextProvider>
                  <App />
                </MovementContextProvider>
              </CameraContextProvider>
            </ScreenRouterContextProvider>
          </VisitorDataContextProvider>
        </LRApiReadConfig>
      </LayoutContextProvider>
    </ReduxProvider>
  );
}

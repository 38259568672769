/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 assets/3d/Walls/Walls.gltf
Created: Sun May 28 23:04:19 2023
*/

import { useGLTF, useTexture } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import React from 'react';

import { ExtendedGLTF } from '../../../types';

export default function Model() {
  const { nodes, materials } = useGLTF('/3d/Walls/Walls.gltf') as ExtendedGLTF;
  const lightMap = useTexture('/3d/walls_ceiling_floor_Lightmap.jpg');
  const { scene } = useThree();

  materials.Walls_mat.lightMap = lightMap;
  materials.Walls_mat.lightMapIntensity = 1;
  materials.Walls_mat.lightMap.flipY = false;
  materials.Walls_mat.envMap = scene.background;
  materials.Walls_mat.envMapIntensity = 2;
  materials.Walls_mat.aoMap = lightMap;

  return (
    <group dispose={null}>
      <mesh
        geometry={nodes.Walls.geometry}
        material={materials.Walls_mat}
        position={[4.94, 1.45, 0.08]}
      />
    </group>
  );
}

useGLTF.preload([
  '/3d/Walls/Walls.gltf',
  '/3d/walls_ceiling_floor_Lightmap.jpg',
]);

import { Stats } from '@react-three/drei';
import * as React from 'react';

import { Room } from '../3d';
import { useCameraMovement, useEnvMap } from '../hooks';
import HoverOutline from './HoverOutline';
import OrbitCamera from './OrbitCamera';
import WalkIndicator from './WalkIndicator';

/**
 * Prepare high-level 3d scene setup: lighting, camera, etc
 */
export default function Scene() {
  useCameraMovement();
  useEnvMap();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const showStats = urlSearchParams.has('perf');

  return (
    <>
      {/* Room general lighting */}
      <ambientLight intensity={0.2} />

      <HoverOutline>
        <Room position={[-3, 0, 0]} />
      </HoverOutline>

      <OrbitCamera />
      <WalkIndicator />

      {showStats && <Stats />}
    </>
  );
}

import { AppLoader } from './src/app';

import * as Sentry from 'sentry-expo';

Sentry.init({
  dsn: 'https://5b923e3f31b5c46194d79ad03f828596@o4506774605725696.ingest.sentry.io/4506780686024704',
  enableInExpoDevelopment: false,
  debug: __DEV__,
});

export default AppLoader;

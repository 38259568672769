/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 assets/3d/ShellBottoms/Shell_bottoms.gltf
Created: Wed May 31 09:24:24 2023
*/

import { useGLTF, useTexture } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import React from 'react';

import { ExtendedGLTF } from '../../../types';
import ModelWrapper from './ModelWrapper';

export default function Model() {
  const { nodes, materials } = useGLTF(
    '/3d/ShellBottoms/Shell_bottoms.gltf',
  ) as ExtendedGLTF;
  const lightMap = useTexture('/3d/ShellBottoms/Shell_bottoms_Lightmap.jpg');
  const { scene } = useThree();

  materials.Shell_bottoms_mat.lightMap = lightMap;
  materials.Shell_bottoms_mat.lightMapIntensity = 2.5;
  materials.Shell_bottoms_mat.lightMap.flipY = false;
  materials.Shell_bottoms_mat.envMap = scene.background;
  materials.Shell_bottoms_mat.envMapIntensity = 3;

  return (
    <ModelWrapper>
      <mesh
        geometry={nodes.Shell_bottoms.geometry}
        material={materials.Shell_bottoms_mat}
        castShadow
        receiveShadow
      />
    </ModelWrapper>
  );
}

useGLTF.preload([
  '/3d/ShellBottoms/Shell_bottoms.gltf',
  '/3d/ShellBottoms/Shell_bottoms_Lightmap.jpg',
]);

/* eslint-disable dot-notation */
/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 assets/3d/Seashell4/Seashell_4.gltf
Created: Sun Jun 11 12:39:39 2023
*/

import { useGLTF, useTexture } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import React from 'react';

import { ExtendedGLTF } from '../../../types';
import ModelWrapper from './ModelWrapper';

export default function Model() {
  const { nodes, materials } = useGLTF(
    '/3d/Seashell4/Seashell_4.gltf',
  ) as ExtendedGLTF;
  const lightMap = useTexture('/3d/Seashell4/Seashell_4_Lightmap.jpg');
  const { scene } = useThree();

  materials['Seashell_4_mat'].lightMap = lightMap;
  materials['Seashell_4_mat'].lightMapIntensity = 0;
  materials['Seashell_4_mat'].lightMap.flipY = false;
  materials['Seashell_4_mat'].envMap = scene.background;
  materials['Seashell_4_mat'].envMapIntensity = 2;

  return (
    <ModelWrapper>
      <mesh
        geometry={nodes.Seashell_4.geometry}
        material={materials['Seashell _4_mat']}
        castShadow
        receiveShadow
      />
    </ModelWrapper>
  );
}

useGLTF.preload([
  '/3d/Seashell4/Seashell_4.gltf',
  '/3d/Seashell4/Seashell_4_Lightmap.jpg',
]);

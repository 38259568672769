import { ApiReadProvider } from 'api-read-hook';
import * as React from 'react';

import useReader from './use-reader';

type Props = {
  children: React.ReactNode;
};

export default function LRApiReadConfig({ children }: Props) {
  const reader = useReader();
  return <ApiReadProvider config={{ reader }}>{children}</ApiReadProvider>;
}

import * as React from 'react';
import { StyleProp, TextStyle } from 'react-native';
import { StyleSheet, Text } from 'react-native';

import { Color } from '../constants';

export type Typeface =
  | 'body1'
  | 'body2'
  | 'button'
  | 'captionM'
  | 'captionR'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'titleL'
  | 'titleS'
  | 'subtitle';

type Props = React.ComponentProps<typeof Text> & {
  typeface?: Typeface;
  color?: keyof typeof Color;
  textAlign?: 'left' | 'center' | 'right';
  style?: StyleProp<TextStyle>;
  children: React.ReactNode;
};

export default function LRText({
  typeface = 'body1',
  color = 'darkBg',
  textAlign = 'left',
  style,
  children,
  ...rest
}: Props) {
  return (
    <Text
      style={[
        typefaceStyles[typeface],
        textAlign && { textAlign },
        { color: Color[color] },
        style,
      ]}
      {...rest}
    >
      {children}
    </Text>
  );
}

export const typefaceStyles = StyleSheet.create({
  body1: {
    fontSize: 16,
    lineHeight: 19,
    fontFamily: 'DMSans_500Medium',
  },
  body2: {
    fontSize: 14,
    lineHeight: 18,
    fontFamily: 'DMSans_500Medium',
  },
  button: {
    fontSize: 16,
    lineHeight: 18,
    fontFamily: 'DMSans_500Medium',
  },
  captionM: {
    fontSize: 12,
    lineHeight: 14,
    fontFamily: 'DMSans_500Medium',
  },
  captionR: {
    fontSize: 10,
    lineHeight: 14,
    fontFamily: 'DMSans_400Regular',
  },
  h1: {
    fontSize: 32,
    lineHeight: 32,
    fontFamily: 'DMSerifDisplay_400Regular',
  },
  h2: {
    fontSize: 20,
    lineHeight: 20,
    fontFamily: 'DMSans_400Regular',
  },
  h3: {
    fontSize: 18,
    lineHeight: 21,
    fontFamily: 'DMSans_700Bold',
  },
  h4: {
    fontSize: 16,
    lineHeight: 19,
    fontFamily: 'DMSans_400Regular',
  },
  titleL: {
    fontSize: 55,
    lineHeight: 61,
    fontFamily: 'DMSerifDisplay_400Regular',
  },
  titleS: {
    fontSize: 26,
    lineHeight: 34,
    fontFamily: 'DMSans_500Medium',
  },
  subtitle: {
    fontSize: 22,
    lineHeight: 28,
    fontFamily: 'DMSans_500Medium',
  },
});

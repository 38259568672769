/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 assets/3d/TV/TV.gltf
Created: Sun May 28 23:44:37 2023
*/

import { Plane, useGLTF, useTexture } from '@react-three/drei';
import { Vector3 } from '@react-three/fiber';
import React from 'react';

import { Api } from '../../../../api';
import { useCroppedTexture } from '../../hooks';

const POSITIONS: Vector3[] = [
  [2.649, 2.341, 2.62],
  [2.1, 2.341, 2.62],
  [1.55, 2.341, 2.62],
  [1.01, 2.341, 2.62],
  [2.649, 1.751, 2.62],
  [2.1, 1.751, 2.62],
  [1.55, 1.751, 2.62],
  [1.01, 1.751, 2.62],
];

type Props = {
  upload: NonNullable<
    NonNullable<Api.Friendship.V_UserSub['album']>['safeCover']
  >['upload'];
  index: number;
};

export default function TVImageCell({ upload, index }: Props) {
  if (!upload.pathOrganiserCover || !upload.file.dimensions) return null;
  const imageTexture = useCroppedTexture({
    sourceUrl: upload.pathOrganiserCover,
    sourceDimensions: upload.file.dimensions,
    targetAspectRatio: 1,
  });

  return (
    <Plane
      args={[0.55, 0.59, 1]} // Width, height, segments
      position={POSITIONS[index]} // Adjust position as needed
      rotation={[0, Math.PI, 0]}
    >
      <meshBasicMaterial attach="material" map={imageTexture} />
    </Plane>
  );
}

useGLTF.preload(['/3d/TV/TV.gltf', '/3d/TV/TV_Lightmap.jpg']);

import { useGLTF } from '@react-three/drei';
import { Select } from '@react-three/postprocessing';
import React from 'react';
import * as THREE from 'three';

import { Api } from '../../../../api';
import { useScreenRouterContext } from '../../../../contexts';
import { useHoverContext, useMovementContext } from '../../contexts';
import { useCroppedTexture } from '../../hooks';

type Props = React.ComponentProps<'group'> & {
  roomTrigger: Api.User.V_Visitor['roomTriggers'][0];
};

export default function LargeFrame({ roomTrigger, ...rest }: Props) {
  const { album } = roomTrigger;
  if (!album) {
    throw new Error(
      'LargeFrame should only be rendered on RoomTriggers with an album',
    );
  }
  const upload = album.safeCover?.upload;
  if (!upload) return null;

  const { nodes, materials } = useGLTF('/3d/LargeFrame/LargeFrame.gltf');
  const { hoverTarget, setHoverTarget } = useHoverContext();
  const { setMovementTarget } = useMovementContext();
  const { showScreen } = useScreenRouterContext();

  const isLandscape =
    parseInt(upload.file.dimensions[0], 10) >=
    parseInt(upload.file.dimensions[1], 10);

  const texture = useCroppedTexture({
    sourceUrl: upload.pathOrganiserCover,
    sourceDimensions: upload.file.dimensions,
    targetAspectRatio: isLandscape ? 4 / 3 : 3 / 4,
  });

  // Clone the material so each model instance can show something different
  const clonedMaterial = materials.Large_Frames_Landscape_Image_001_mat.clone();
  clonedMaterial.map = texture;
  clonedMaterial.map.flipY = false;
  clonedMaterial.needsUpdate = true;

  const hoverId = `LargeFrame.${roomTrigger.id}`;
  const posX = 2.8 + 1.2 * ((roomTrigger.slot ?? 1) - 1);
  const pos = new THREE.Vector3(posX, 1.9, -2.41);

  return (
    <group {...rest} position={pos} dispose={null}>
      <mesh
        userData={{ hoverTarget: hoverId }}
        onPointerOver={() => setHoverTarget(hoverId)}
        onPointerOut={() => setHoverTarget(null)}
        onClick={() => {
          setMovementTarget(pos.add(new THREE.Vector3(-3, 0, 1)));
          setTimeout(() => {
            showScreen(['Album', { albumId: album.id }]);
          }, 500);
        }}
      >
        <boxGeometry args={[1.07, 1.07, 0.05]} />
        <meshStandardMaterial
          color="orange"
          transparent
          // Set to 1 to see hover area
          opacity={0}
        />
      </mesh>
      {isLandscape ? (
        <Select enabled={hoverTarget === hoverId}>
          <group>
            <mesh
              geometry={nodes.Large_Frames_Landscape_Frame_001.geometry}
              material={materials.Large_Frames_Frame_mat}
            />
            <mesh
              geometry={nodes.Large_Frames_Landscape_White_001.geometry}
              material={materials.Large_Frames_White_mat}
            />
            <mesh
              geometry={nodes.Large_Frames_Landscape_Image_001.geometry}
              material={clonedMaterial}
            />
          </group>
        </Select>
      ) : (
        <Select enabled={hoverTarget === hoverId}>
          <group>
            <mesh
              geometry={nodes.Large_Frames_Portrait_Image_001.geometry}
              material={clonedMaterial}
            />
            <mesh
              geometry={nodes.Large_Frames_Portrait_Frame_001.geometry}
              material={materials.Large_Frames_Frame_mat}
            />
            <mesh
              geometry={nodes.Large_Frames_Portrait_White_001.geometry}
              material={materials.Large_Frames_White_mat}
            />
          </group>
        </Select>
      )}
    </group>
  );
}

useGLTF.preload('/3d/LargeFrame/LargeFrame.gltf');

import { ResizeObserver } from '@juggle/resize-observer';
import { Canvas } from '@react-three/fiber';
import * as React from 'react';

import { HoverContextProvider } from '../contexts';
import Scene from './Scene';

/**
 * Initialise the canvas/renderer, and set up context providers
 */
export default function RootCanvas() {
  return (
    <Canvas
      // Was causing bugs on some platforms after closing an overlay
      // frameloop={screenVisible ? 'never' : 'demand'}
      frameloop="demand"
      resize={{
        // Polyfill for old iPad
        polyfill: ResizeObserver,
      }}
      linear
      shadows
    >
      <React.Suspense fallback={null}>
        <HoverContextProvider>
          <Scene />
        </HoverContextProvider>
      </React.Suspense>
    </Canvas>
  );
}

import * as React from 'react';
import { StyleSheet, View } from 'react-native';

import { useScreenRouterContext } from '../contexts';
import { FriendsWallScreen, ViewAlbumScreen } from '../pods/album';
import { LandingScreen } from '../pods/landing';

/**
 * The 3D room is always mounted in the background, but this controls screens
 * that are optionally rendered on top.
 */
export default function ScreenRouter() {
  const { lastScreen, isScreenVisible } = useScreenRouterContext();

  return (
    <View
      pointerEvents={isScreenVisible ? 'auto' : 'none'}
      style={[
        StyleSheet.absoluteFill,
        {
          zIndex: 100,
          opacity: isScreenVisible ? 1 : 0,
          ['transitionProperty' as any]: 'all',
          ['transitionDuration' as any]: '500ms',
        },
      ]}
    >
      {lastScreen[0] === 'Landing' && <LandingScreen />}
      {lastScreen[0] === 'Album' && <ViewAlbumScreen params={lastScreen[1]} />}
      {lastScreen[0] === 'Friends_Wall' && <FriendsWallScreen />}
    </View>
  );
}

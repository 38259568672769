import * as React from 'react';
import { StyleSheet, View } from 'react-native';

import { Api } from '../../api';
import {
  IconButton,
  LRText,
  PaddedArea,
  ScreenError,
  ScreenLoading,
  Spacer,
} from '../../components';
import { IconCrossBig } from '../../components/icons';
import { Dimen } from '../../constants';
import {
  useLayoutContext,
  useScreenRouterContext,
  useVisitorDataContext,
} from '../../contexts';
import { useMovementContext } from '../room/contexts';
import FriendListItem from './FriendListItem';

export default function FriendsWallScreen() {
  const { visitorData } = useVisitorDataContext();
  const { isDesktopLayout } = useLayoutContext();
  const { dismissScreen } = useScreenRouterContext();
  const { popBack } = useMovementContext();

  if (!visitorData)
    throw new Error('Reached FriendsWallScreen without visitorData');
  const api = Api.Friendship.useReadCollectionFriendships(visitorData.id);

  if (api.error) return <ScreenError {...api} />;
  if (!api.data) return <ScreenLoading />;

  const friends = api.data['hydra:member'].filter((friend) => friend.album);

  const onDismiss = () => {
    dismissScreen();
    setTimeout(() => {
      popBack();
    }, 100);
  };

  return (
    <>
      <PaddedArea v h={isDesktopLayout ? 2 : 1} style={styles.container}>
        <Spacer fill />
        <LRText
          color="whiteHigh"
          typeface={isDesktopLayout ? 'titleL' : 'titleS'}
        >
          {visitorData.firstName}'s friends
        </LRText>
        <Spacer size={isDesktopLayout ? 2 : 1} />
        <View
          style={[
            styles.contentContainer,
            { gap: isDesktopLayout ? Dimen.spacing * 2 : Dimen.spacing },
          ]}
        >
          {friends.map((friend) => (
            <FriendListItem friendship={friend} />
          ))}
        </View>
        <Spacer fill />
      </PaddedArea>
      <View style={styles.closeButton}>
        <IconButton
          size={isDesktopLayout ? 52 : 40}
          onPress={onDismiss}
          icon={IconCrossBig}
          iconSize={isDesktopLayout ? 52 : 40}
        />
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: 'scroll',
    backgroundColor: '#000a',
    // @ts-ignore
    cursor: 'default',
    alignItems: 'center',
  },
  contentContainer: {
    width: '100%',
    maxWidth: 1400,
    overflow: 'hidden',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  closeButton: {
    position: 'absolute',
    top: Dimen.spacing,
    right: Dimen.spacing,
  },
});

import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { Color } from '../../constants';

type Props = {
  size?: number;
  color?: string;
};

export default function IconCrossBig({
  size = 63,
  color = Color.whiteHigh,
}: Props) {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 63 63"
      fill="none"
      preserveAspectRatio="xMidYMid meet"
    >
      <Path
        d="M49.877 13.127l-36.75 36.75M13.127 13.127l36.75 36.75"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

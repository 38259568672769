// @flow

import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { Color } from '../../constants';

type Props = {
  size?: number;
  color?: string;
};

export default function IconChevronRight({
  size = 24,
  color = Color.whiteHigh,
}: Props) {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      preserveAspectRatio="xMidYMid meet"
    >
      <Path
        d="M5 21.684l2.105 2.105L19 11.895 7.105 0 5 2.105l9.79 9.79L5 21.684z"
        fill={color}
      />
    </Svg>
  );
}

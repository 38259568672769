/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 assets/3d/Lillies/Lillies.gltf
Created: Sun Jun 11 23:33:41 2023
*/

import { useGLTF, useTexture } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import React from 'react';

import { ExtendedGLTF } from '../../../types';
import { ModelWrapper } from '.';

export default function Model() {
  const { nodes, materials } = useGLTF(
    '/3d/Lillies/Lillies.gltf',
  ) as ExtendedGLTF;
  const lightMap = useTexture('/3d/Lillies/Lillies_Lightmap.jpg');
  const { scene } = useThree();

  materials.Lillies_flowers.lightMap = lightMap;
  materials.Lillies_flowers.lightMapIntensity = 1.1;
  materials.Lillies_flowers.lightMap.flipY = false;
  materials.Lillies_flowers.envMap = scene.background;
  materials.Lillies_flowers.envMapIntensity = 1.1;

  materials.Lillies_vase.lightMap = lightMap;
  materials.Lillies_vase.lightMapIntensity = 0;
  materials.Lillies_vase.lightMap.flipY = false;
  materials.Lillies_vase.envMap = scene.background;
  materials.Lillies_vase.envMapIntensity = 1.5;

  return (
    <ModelWrapper>
      <mesh
        geometry={nodes.Lillies_flowers.geometry}
        material={materials.Lillies_flowers}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.Lillies_vase.geometry}
        material={materials.Lillies_vase}
        castShadow
        receiveShadow
      />
    </ModelWrapper>
  );
}

useGLTF.preload('/3d/Lillies/Lillies.gltf', '/3d/Lillies/Lillies_Lightmap.jpg');

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 assets/3d/Mac/Mac.gltf
Created: Sun Jun 11 21:33:02 2023
*/

import { useGLTF, useTexture } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { Select } from '@react-three/postprocessing';
import React from 'react';
import * as THREE from 'three';

import { Api } from '../../../api';
import { useScreenRouterContext } from '../../../contexts';
import { ExtendedGLTF } from '../../../types';
import { useHoverContext, useMovementContext } from '../contexts';
import { MacCover } from './dynamic';
import ModelWrapper from './ModelWrapper';

type Props = {
  roomtriggers: Api.User.V_Visitor['roomTriggers'];
  title: string;
};

export default function Model({ roomtriggers, title }: Props) {
  const { nodes, materials } = useGLTF('/3d/Mac/Mac.gltf') as ExtendedGLTF;
  const lightMap = useTexture('/3d/Mac/Mac_Lightmap.jpg');
  const { scene } = useThree();
  const { hoverTarget, setHoverTarget } = useHoverContext();
  const { setMovementTarget } = useMovementContext();
  const { showScreen } = useScreenRouterContext();

  const hoverId = `Welcome_Note`;
  materials.Mac_mat.lightMap = lightMap;
  materials.Mac_mat.lightMapIntensity = 1;
  materials.Mac_mat.lightMap.flipY = false;
  materials.Mac_mat.envMap = scene.background;
  materials.Mac_mat.envMapIntensity = 4;

  const albums = roomtriggers.filter(
    (roomTrigger) => roomTrigger.album?.safeCover?.upload,
  );

  return (
    <>
      <ModelWrapper>
        <Select enabled={hoverTarget === hoverId}>
          <mesh
            geometry={nodes.Mac.geometry}
            material={materials.Mac_mat}
            userData={{ hoverTarget: hoverId }}
            onPointerOver={() => {
              albums && albums.length > 0 && setHoverTarget(hoverId);
            }}
            onPointerOut={() => {
              albums && albums.length > 0 && setHoverTarget(null);
            }}
            onClick={() => {
              if (albums && albums[0].album && albums[0].album.id) {
                setMovementTarget(new THREE.Vector3(1.44, 1.4, 1.6));
                setTimeout(() => {
                  showScreen(['Album', { albumId: albums[0].album!.id }]);
                }, 500);
              }
            }}
            castShadow
            receiveShadow
          />
        </Select>
      </ModelWrapper>
      {albums && albums[0] && <MacCover title={title} album={albums[0]} />}
    </>
  );
}

useGLTF.preload('/3d/Mac/Mac.gltf', '/3d/Mac/Mac_Lightmap.jpg');

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 assets/3d/TV/TV.gltf
Created: Sun May 28 23:44:37 2023
*/

import { Plane, useGLTF, useTexture } from '@react-three/drei';
import { Vector3, useThree } from '@react-three/fiber';
import { Select } from '@react-three/postprocessing';
import React from 'react';
import * as THREE from 'three';

import { Api } from '../../../api';
import { useScreenRouterContext } from '../../../contexts';
import { ExtendedGLTF } from '../../../types';
import { useHoverContext, useMovementContext } from '../contexts';
import { TVImageCell } from './dynamic';
import ModelWrapper from './ModelWrapper';

const FRIENDS_WALL_HOVER_TARGET = 'friends_wall';

const POSITIONS: Vector3[] = [
  [2.649, 2.341, 2.62],
  [2.1, 2.341, 2.62],
  [1.55, 2.341, 2.62],
  [1.01, 2.341, 2.62],
  [2.649, 1.751, 2.62],
  [2.1, 1.751, 2.62],
  [1.55, 1.751, 2.62],
  [1.01, 1.751, 2.62],
];

type Props = {
  friendships: Api.Friendship.V_UserSub[];
};

export default function Model({ friendships }: Props) {
  const { nodes, materials } = useGLTF('/3d/TV/TV.gltf') as ExtendedGLTF;
  const lightMap = useTexture('/3d/TV/TV_Lightmap.jpg');

  const { scene } = useThree();
  const { hoverTarget, setHoverTarget } = useHoverContext();
  const { setMovementTarget } = useMovementContext();
  const { showScreen } = useScreenRouterContext();

  const hasFriends = friendships.length > 0;
  const coverUploads = friendships.map((friendship) => {
    return friendship.friend?.avatar ?? undefined;
  });

  const coverUploadsFilled = coverUploads.concat(
    new Array(8 - coverUploads.length).fill(undefined),
  );

  materials.TV_mat.lightMap = lightMap;
  materials.TV_mat.flipY = false;
  materials.TV_mat.lightMapIntensity = 2;
  materials.TV_mat.envMap = scene.background;
  materials.TV_mat.envMapIntensity = 2;

  return (
    <>
      <ModelWrapper>
        <Select
          enabled={hoverTarget === FRIENDS_WALL_HOVER_TARGET && hasFriends}
        >
          <mesh
            castShadow
            receiveShadow
            userData={{ hoverTarget: FRIENDS_WALL_HOVER_TARGET }}
            onPointerOver={() => {
              if (hasFriends) {
                setHoverTarget(FRIENDS_WALL_HOVER_TARGET);
              }
            }}
            onPointerOut={() => {
              if (hasFriends) {
                setHoverTarget(null);
              }
            }}
            onClick={() => {
              if (hasFriends) {
                setMovementTarget(new THREE.Vector3(-1.3, 2, 1.3));
                setTimeout(() => {
                  showScreen(['Friends_Wall']);
                }, 500);
              }
            }}
            geometry={nodes.TV.geometry}
            material={materials.TV_mat}
          />
        </Select>
      </ModelWrapper>
      {coverUploadsFilled.map((upload, i) => {
        if (upload) {
          return <TVImageCell key={`friend-${i}`} upload={upload} index={i} />;
        } else {
          return (
            <Plane
              key={`friend-${i}`}
              args={[0.55, 0.59, 1]} // Width, height, segments
              position={POSITIONS[i]} // Adjust position as needed
              rotation={[0, Math.PI, 0]}
            >
              <meshBasicMaterial color={'#000000'} />
            </Plane>
          );
        }
      })}
    </>
  );
}

useGLTF.preload(['/3d/TV/TV.gltf', '/3d/TV/TV_Lightmap.jpg']);

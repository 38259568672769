/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 assets/3d/Floor/Floor.gltf
Created: Sun Jun 11 19:54:16 2023
*/

import { useGLTF, useTexture } from '@react-three/drei';
import React from 'react';

import { ExtendedGLTF } from '../../../types';
import ModelWrapper from './ModelWrapper';

export default function Floor() {
  const lightMap = useTexture('/3d/walls_ceiling_floor_Lightmap.jpg');
  const { nodes, materials } = useGLTF('/3d/Floor/Floor.gltf') as ExtendedGLTF;

  materials.Floor_mat.lightMap = lightMap;
  materials.Floor_mat.lightMapIntensity = 1;
  materials.Floor_mat.lightMap.flipY = false;
  materials.Rug_mat.lightMap = lightMap;
  materials.Rug_mat.lightMapIntensity = 1;
  materials.Rug_mat.lightMap.flipY = false;
  return (
    <ModelWrapper>
      <mesh
        receiveShadow
        geometry={nodes.Floor.geometry}
        material={materials.Floor_mat}
      />
      <mesh
        receiveShadow
        geometry={nodes.Rug.geometry}
        material={materials.Rug_mat}
      />
    </ModelWrapper>
  );
}

useGLTF.preload([
  '/3d/Floor/Floor.gltf',
  '/3d/walls_ceiling_floor_Lightmap.jpg',
]);

import { GroupProps } from '@react-three/fiber';
import { useApiRead } from 'api-read-hook';
import * as React from 'react';

import { V_UserSub } from '../../../api/endpoints/Friendship';
import { useVisitorDataContext } from '../../../contexts';
import { HydraCollection } from '../../../types';
import Armchairs from './Armchairs';
import Books from './Books';
import Bookshelves from './Bookshelves';
import Boxes from './Boxes';
import Ceiling from './Ceiling';
import Cushions from './Cushions';
import Desk from './Desk';
import DeskChair from './DeskChair';
import DoorSkirtings from './DoorSkirtings';
import { Frame, LargeFrame } from './dynamic';
import FigPlant from './FigPlant';
import Floor from './Floor';
import Lamps from './Lamps';
import Lillies from './Lillies';
import Mac from './Mac';
import Map from './Map';
import Microphone from './Microphone';
import Mirror from './Mirror';
import Orchid from './Orchid';
import Pots from './Pots';
import Safe from './Safe';
import Seashell1 from './Seashell1';
import Seashell2 from './Seashell2';
import Seashell3 from './Seashell3';
import Seashell4 from './Seashell4';
import SeaSky from './SeaSky';
import SetOfBooks from './SetOfBooks';
import ShellBottoms from './ShellBottoms';
import Sideboards from './Sideboards';
import Sofa from './Sofa';
import Suitcase from './Suitcase';
import Tables from './Tables';
import TV from './TV';
import Walls from './Walls';
import Window from './Window';

type Props = GroupProps;

/**
 * Composing all the 3D models of the room together
 */
export default function Room({ ...props }: Props) {
  const { visitorData } = useVisitorDataContext();

  const friendships = useApiRead<HydraCollection<V_UserSub>>(
    visitorData ? `/users/${visitorData.id}/friendships` : null,
  ).data?.['hydra:member'];

  const friendshipsWithAlbums = friendships
    ? friendships
        .filter((friendship) => {
          if (friendship.album) {
            return friendship;
          }
        })
        .slice(0, 8)
    : [];

  const books = visitorData?.roomTriggers.filter(
    (trigger) => trigger.type === 'book',
  );

  const macAlbum = visitorData?.roomTriggers.filter(
    (trigger) => trigger.type === 'welcome_note',
  );

  return (
    <group {...props} dispose={null}>
      <Floor />
      <SeaSky />
      <Walls />
      <Armchairs />
      <Books books={books} />
      <Boxes />
      <Bookshelves />
      <Ceiling />
      <Desk />
      <DeskChair />
      <DoorSkirtings />
      <Cushions />
      <FigPlant />
      <Lamps />
      <Map />
      <Mac
        title={`${visitorData?.firstName} ${visitorData?.lastName}`}
        roomtriggers={macAlbum ?? []}
      />
      <Lillies />
      <Microphone />
      <Orchid />
      <Pots />
      <Safe />
      <Mirror />
      <Seashell1 />
      <Seashell2 />
      <Seashell3 />
      <Seashell4 />
      <ShellBottoms />
      <Sideboards />
      <SetOfBooks />
      <Sofa />
      <Suitcase />
      <Tables />
      <TV friendships={friendshipsWithAlbums} />
      <Window />
      {visitorData?.roomTriggers
        .filter((rt) => rt.type === 'wall_picture' && rt.album)
        .map((rt) => <LargeFrame key={rt['@id']} roomTrigger={rt} />)}
      {visitorData?.roomTriggers
        .filter((rt) => rt.type === 'photo_frame' && rt.album)
        .map((rt) => <Frame key={rt['@id']} roomTrigger={rt} />)}
    </group>
  );
}

import * as React from 'react';

type Screen = ['Landing'] | ['Album', { albumId: string }] | ['Friends_Wall'];

const ScreenRouterContext = React.createContext<{
  lastScreen: Screen;
  isScreenVisible: boolean;
  showScreen: (screen: Screen) => void;
  dismissScreen: () => void;
}>({
  lastScreen: ['Landing'],
  isScreenVisible: false,
  showScreen: () => {},
  dismissScreen: () => {},
});

type Props = {
  children: React.ReactNode;
};

export function ScreenRouterContextProvider({ children }: Props) {
  const [lastScreen, setLastScreen] = React.useState<Screen>(['Landing']);
  const [isScreenVisible, setScreenVisible] = React.useState(true);

  const showScreen = React.useCallback((screen: Screen) => {
    setLastScreen(screen);
    setScreenVisible(true);
  }, []);

  const dismissScreen = React.useCallback(() => {
    setScreenVisible(false);
  }, []);

  return (
    <ScreenRouterContext.Provider
      value={{ lastScreen, isScreenVisible, showScreen, dismissScreen }}
    >
      {children}
    </ScreenRouterContext.Provider>
  );
}

export function useScreenRouterContext() {
  return React.useContext(ScreenRouterContext);
}

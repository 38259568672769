import * as React from 'react';
import * as THREE from 'three';

import { INITIAL_TARGET } from '../constants';
import { useCameraContext } from './CameraContext';

const MovementContext = React.createContext<{
  orbitRef: React.Ref;
  desiredTargetPoint: THREE.Vector3;
  currentAnimVector: THREE.Vector3 | null;
  currentAnimStartDistance: number | null;
  setMovementFinished: () => void;
  setMovementTarget: (target: THREE.Vector3) => void;
  popBack: () => void;
}>({
  orbitRef: undefined as any,
  desiredTargetPoint: undefined as any,
  currentAnimVector: null,
  currentAnimStartDistance: null,
  setMovementFinished: () => {},
  setMovementTarget: () => {},
  popBack: () => {},
});

type Props = {
  children: React.ReactNode;
};

export function MovementContextProvider({ children }: Props) {
  const { orbitRef } = useCameraContext();

  const [desiredTargetPoint, setDesiredTargetPoint] =
    React.useState(INITIAL_TARGET);
  const [popBackTargetPoint, setPopBackTargetPoint] =
    React.useState(INITIAL_TARGET);

  const [currentAnimVector, setCurrentAnimVector] =
    React.useState<THREE.Vector3 | null>(null);
  const [currentAnimStartDistance, setCurrentAnimStartDistance] =
    React.useState<number | null>(null);

  const setMovementFinished = React.useCallback(() => {
    setCurrentAnimVector(null);
    setCurrentAnimStartDistance(null);
  }, []);

  const setMovementTarget = React.useCallback(
    (target: THREE.Vector3): void => {
      if (!orbitRef.current) return;
      const diff = target.clone().sub(orbitRef.current.target).normalize();
      setDesiredTargetPoint(target);
      setPopBackTargetPoint(orbitRef.current.target.clone());
      setCurrentAnimVector(diff);
      setCurrentAnimStartDistance(target.distanceTo(orbitRef.current.target));
    },
    [desiredTargetPoint, orbitRef],
  );

  const popBack = React.useCallback(() => {
    setMovementTarget(popBackTargetPoint);
  }, [popBackTargetPoint, setMovementTarget]);

  return (
    <MovementContext.Provider
      value={{
        orbitRef,
        desiredTargetPoint,
        currentAnimVector,
        currentAnimStartDistance,
        popBack,
        setMovementFinished,
        setMovementTarget,
      }}
    >
      {children}
    </MovementContext.Provider>
  );
}

export function useMovementContext() {
  return React.useContext(MovementContext);
}

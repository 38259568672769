/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 assets/3d/Ceiling/Ceiling.gltf
Created: Sun May 28 23:13:54 2023
*/

import { useGLTF, useTexture } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import React from 'react';

import { ExtendedGLTF } from '../../../types';
import ModelWrapper from './ModelWrapper';

export default function Model() {
  const { nodes, materials } = useGLTF(
    '/3d/Ceiling/Ceiling.gltf',
  ) as ExtendedGLTF;
  const lightMap = useTexture('/3d/walls_ceiling_floor_Lightmap.jpg');
  const { scene } = useThree();

  materials.Ceiling_mat.lightMap = lightMap;
  materials.Ceiling_mat.lightMapIntensity = 1.5;
  materials.Ceiling_mat.lightMap.flipY = false;
  materials.Ceiling_mat.envMap = scene.background;
  materials.Ceiling_mat.envMapIntensity = 1.5;
  materials.Ceiling_mat.aoMap = lightMap;

  return (
    <ModelWrapper>
      <mesh
        receiveShadow
        geometry={nodes.Ceiling.geometry}
        material={materials.Ceiling_mat}
      />
    </ModelWrapper>
  );
}

useGLTF.preload([
  '/3d/Ceiling/Ceiling.gltf',
  '/3d/walls_ceiling_floor_Lightmap.jpg',
]);

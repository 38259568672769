/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 assets/3d/Armchairs/Armchairs.gltf
Created: Sun Jun 11 23:29:06 2023
*/

import { useGLTF, useTexture } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import React from 'react';

import { ExtendedGLTF } from '../../../types';
import ModelWrapper from './ModelWrapper';

export default function Model() {
  const { nodes, materials } = useGLTF(
    '/3d/Armchairs/Armchairs.gltf',
  ) as ExtendedGLTF;
  const lightMap = useTexture('/3d/Armchairs/Armchairs_Lightmap.jpg');
  const { scene } = useThree();

  materials.ARMCHAIRS_fabric.lightMap = lightMap;
  materials.ARMCHAIRS_fabric.lightMapIntensity = 5;
  materials.ARMCHAIRS_fabric.lightMap.flipY = false;
  materials.ARMCHAIRS_fabric.envMap = scene.background;
  materials.ARMCHAIRS_fabric.envMapIntensity = 1;
  materials.ARMCHAIRS_fabric.aoMap = lightMap;

  materials.ARMCHAIRS_wood.lightMap = lightMap;
  materials.ARMCHAIRS_wood.lightMapIntensity = 2;
  materials.ARMCHAIRS_wood.lightMap.flipY = false;
  materials.ARMCHAIRS_wood.envMap = scene.background;
  materials.ARMCHAIRS_wood.envMapIntensity = 3;

  materials.ARMCHAIRS_metal.lightMap = lightMap;
  materials.ARMCHAIRS_metal.lightMapIntensity = 2;
  materials.ARMCHAIRS_metal.lightMap.flipY = false;
  materials.ARMCHAIRS_metal.envMap = scene.background;
  materials.ARMCHAIRS_metal.envMapIntensity = 3;

  return (
    <ModelWrapper>
      <mesh
        geometry={nodes.ARMCHAIRS_fabric.geometry}
        material={materials.ARMCHAIRS_fabric}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.ARMCHAIRS_wood.geometry}
        material={materials.ARMCHAIRS_wood}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.ARMCHAIRS_metal.geometry}
        material={materials.ARMCHAIRS_metal}
        castShadow
        receiveShadow
      />
    </ModelWrapper>
  );
}

useGLTF.preload([
  '/3d/Armchairs/Armchairs.gltf',
  '/3d/Armchairs/Armchairs_Lightmap.jpg',
]);

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 assets/3d/Bookshelves/Bookshelves.gltf
Created: Wed May 31 14:00:15 2023
*/

import { useGLTF, useTexture } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import React from 'react';

import { ExtendedGLTF } from '../../../types';
import ModelWrapper from './ModelWrapper';

export default function Model() {
  const { nodes, materials } = useGLTF(
    '/3d/Bookshelves/Bookshelves.gltf',
  ) as ExtendedGLTF;
  const lightMap = useTexture('/3d/Bookshelves/Bookshelves_Lightmap.jpg');
  const { scene } = useThree();

  materials.Bookshelves_mat.lightMap = lightMap;
  materials.Bookshelves_mat.lightMapIntensity = 5;
  materials.Bookshelves_mat.lightMap.flipY = false;
  materials.Bookshelves_mat.envMap = scene.background;
  materials.Bookshelves_mat.envMapIntensity = 5;
  materials.Bookshelves_mat.aoMap = lightMap;

  return (
    <ModelWrapper>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Bookshelves.geometry}
        material={materials.Bookshelves_mat}
      />
    </ModelWrapper>
  );
}

useGLTF.preload([
  '/3d/Bookshelves/Bookshelves.gltf',
  '/3d/Bookshelves/Bookshelves_Lightmap.jpg',
]);

import * as React from 'react';
import { Pressable, View } from 'react-native';

import { apiErrorToSentryExtras } from '../api';
import { Style } from '../constants';
import { emailErrorReportUrl, logError } from '../util';
import Button from './Button';
import LRText from './LRText';
import PaddedArea from './PaddedArea';
import Spacer from './Spacer';

type Props = {
  error: Error | undefined;
  errorInfo?: React.ErrorInfo;
  invalidate?: () => void;
};

/**
 * If an error can show this screen, it should NOT log the error, and leave
 * responsibilities like showing the logged eventId here.
 */
export default function ScreenError({ error, errorInfo, invalidate }: Props) {
  const [loggedEventId, setLoggedEventId] = React.useState<string | null>(null);

  let displayTitle = 'Something went wrong';
  let displayDesc = 'An unexpected error occured. Please try again later';
  let shouldLog = true;

  if (error?.message.match(/(NetworkError|Cross-Origin Request Blocked)/i)) {
    displayTitle = 'Unable to connect';
    displayDesc =
      'Could not reach our servers. Please make sure you are connected to the internet.';
    shouldLog = false;
  } else if (errorInfo) {
    displayDesc = 'Try refreshing the page.';
  }

  React.useEffect(() => {
    if (shouldLog && error) {
      const eventId = logError(error, 'screenError', {
        level: 'fatal',
        extra: {
          ...(errorInfo ? { errorInfo } : {}),
          ...apiErrorToSentryExtras(error),
        },
      });
      setLoggedEventId(eventId);
    }
  }, [shouldLog, error, errorInfo]);

  return (
    <PaddedArea v={2} h={2} style={[Style.fill, Style.centerBoth]}>
      <View style={[Style.alignCenter, { maxWidth: 600 }]}>
        <LRText typeface="h1" textAlign="center">
          {displayTitle}
        </LRText>
        <Spacer size={0.75} />
        <LRText textAlign="center">{displayDesc}</LRText>
        {loggedEventId && (
          <>
            <Spacer size={0.75} />
            <LRText
              textAlign="center"
              color="gray7"
              typeface="body2"
              selectable
            >
              Reference: {loggedEventId}
            </LRText>
          </>
        )}
      </View>

      {invalidate && (
        <>
          <Spacer size={1} />
          <Button title="Try again" onPress={invalidate} />
        </>
      )}

      <Spacer size={2} />
      <a href={emailErrorReportUrl(loggedEventId)}>
        <LRText color="gray7">Contact Legacy Room support</LRText>
      </a>

      <Spacer size={0.75} />
      <Pressable onPress={() => window.location.reload()}>
        <LRText color="gray7">Refresh the page</LRText>
      </Pressable>
    </PaddedArea>
  );
}

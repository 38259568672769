import * as React from 'react';

import { Api } from '../../api';
import { ScreenError, ScreenLoading } from '../../components';
import ViewAlbumLoaded from './ViewAlbumLoaded';

type Props = {
  params: { albumId: string };
};

export default function ViewAlbumScreen({ params }: Props) {
  const { albumId } = params;

  const api = Api.Album.useReadItem(albumId);

  if (api.error) return <ScreenError {...api} />;
  if (!api.data) return <ScreenLoading />;

  return <ViewAlbumLoaded album={api.data} />;
}

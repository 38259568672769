/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 assets/3d/Orchid/Orchid.gltf
Created: Mon May 29 14:00:40 2023
*/

import { useGLTF, useTexture } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import React from 'react';

import { ExtendedGLTF } from '../../../types';
import ModelWrapper from './ModelWrapper';

export default function Model() {
  const { nodes, materials } = useGLTF(
    '/3d/Orchid/Orchid.gltf',
  ) as ExtendedGLTF;
  const lightMap = useTexture('/3d/Orchid/Orchid_Lightmap.jpg');
  const { scene } = useThree();

  materials.Orchid_Flowers_mat.lightMap = lightMap;
  materials.Orchid_Flowers_mat.lightMapIntensity = 0;
  materials.Orchid_Flowers_mat.lightMap.flipY = false;
  materials.Orchid_Flowers_mat.envMap = scene.background;
  materials.Orchid_Flowers_mat.envMapIntensity = 3;

  materials.Orchid_Twig_mat.lightMap = lightMap;
  materials.Orchid_Twig_mat.lightMapIntensity = 1;
  materials.Orchid_Twig_mat.lightMap.flipY = false;
  materials.Orchid_Twig_mat.envMap = scene.background;
  materials.Orchid_Twig_mat.envMapIntensity = 3;

  materials.Orchid_Leaves_Large_mat.lightMap = lightMap;
  materials.Orchid_Leaves_Large_mat.lightMapIntensity = 2;
  materials.Orchid_Leaves_Large_mat.lightMap.flipY = false;
  materials.Orchid_Leaves_Large_mat.envMap = scene.background;
  materials.Orchid_Leaves_Large_mat.envMapIntensity = 3;

  materials.Orchid_Buds.lightMap = lightMap;
  materials.Orchid_Buds.lightMapIntensity = 2;
  materials.Orchid_Buds.lightMap.flipY = false;
  materials.Orchid_Buds.envMap = scene.background;
  materials.Orchid_Buds.envMapIntensity = 3;

  materials.Orchid_Leaves_Small_mat.lightMap = lightMap;
  materials.Orchid_Leaves_Small_mat.lightMapIntensity = 1;
  materials.Orchid_Leaves_Small_mat.lightMap.flipY = false;
  materials.Orchid_Leaves_Small_mat.envMap = scene.background;
  materials.Orchid_Leaves_Small_mat.envMapIntensity = 3;

  materials['Orchid_Pebbles+mat'].lightMap = lightMap;
  materials['Orchid_Pebbles+mat'].lightMapIntensity = 1;
  materials['Orchid_Pebbles+mat'].lightMap.flipY = false;
  materials['Orchid_Pebbles+mat'].envMap = scene.background;
  materials['Orchid_Pebbles+mat'].envMapIntensity = 1;

  return (
    <ModelWrapper>
      <mesh
        geometry={nodes.Orchid_Twig.geometry}
        material={materials.Orchid_Twig_mat}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.Orchid_Flowers.geometry}
        material={materials.Orchid_Flowers_mat}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.Orchid_Leaves_Large.geometry}
        material={materials.Orchid_Leaves_Large_mat}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.Orchid_Buds.geometry}
        material={materials.Orchid_Buds}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.Orchid_Leaves_Small.geometry}
        material={materials.Orchid_Leaves_Small_mat}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.Orchid_Pebbles.geometry}
        material={materials['Orchid_Pebbles+mat']}
        castShadow
        receiveShadow
      />
    </ModelWrapper>
  );
}

useGLTF.preload(['/3d/Orchid/Orchid.gltf', '/3d/Orchid/Orchid_Lightmap.jpg']);
